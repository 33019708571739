import axios from "axios";
import store from "redux/Store";
import { v4 as uuidv4 } from "uuid";
import { ValidatePayment } from "../../../../util/validations/SMAdminPaymentActionsValidation";

module = "/mlAdmin/playlistCategory/";

export const actionTypes = {
  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,
  ERROR: "ERROR" + module,
  FOCUS_ITEM: "FOCUS_ITEM" + module,
  SELECT_ITEMS: "SELECT_ITEMS" + module,
  QUERY: "QUERY" + module,
  EDIT_ITEM: "EDIT_ITEM" + module,
  POSTING: "POSTING" + module,
  POST_OK: "POSTOK" + module,
  POST_ERROR: "POST_ERROR" + module,
  SET_ALL_PAYMENTLIST: "SET_ALL_PAYMENTLIST" + module,
  CLEAR_ERRORS: "CLEAR_ERRORS" + module,
};

var tId = null;

export const editItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.EDIT_ITEM,
    payload: item,
  });
};
export const clearErrors = (done) => (dispatch) => {
  dispatch({
    type: actionTypes.CLEAR_ERRORS,
    payload: {},
  });
  if (done) done();
};

export const postItem = (action, message, items, done = null) => (dispatch) => {

  if (items.payment) {
    let errors = ValidatePayment(items.payment);
    if (errors) {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: errors
      });
      return;
    }
  }


  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`/sysadmin/payments/${action}`, items, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.POST_OK,
        payload: "Sucess",
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: err.response?.data || { e: (err.message ? err.message : "Error in creating or updating. please retry") },
      });

      if (done) done(null, err);
    });
};

export const setQuery = (query, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.QUERY,
    payload: query,
  });

  if (done) done();
};

export const selectItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.SELECT_ITEMS,
    payload: items,
  });
};

export const focusItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.FOCUS_ITEM,
    payload: item,
  });
};

export const search = (query) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  dispatch({
    type: actionTypes.LOADING,
  });

  tId = uuidv4();

  axios
    .post(`/sysadmin/payments/search`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        dispatch({
          type: actionTypes.LOAD,
          payload: res.data.results,
          total: res.data.total,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
    });
};

export const getAllPaymentList = () => (dispatch) => {
  // dispatch({ type: INBOX_START_LOADING_ALL_USERS });

  const query = {
    senderId: 0,
    filter: "",
    keyword: "",
  };

  axios
    .post("/sysadmin/payments/getpaymentlist", query)
    .then((response) => {
      dispatch({
        type: actionTypes.SET_ALL_PAYMENTLIST,
        list: response.data,
      });
      // dispatch({ type: INBOX_STOP_LOADING_ALL_USERS });
    })
    .catch((error) => {
      // dispatch({
      //   // type: INBOX_SET_ERROR_LOADING_ALL_USERS,
      //   users: [],
      //   errors: error,
      // });
    });
};
