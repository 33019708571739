import { _manageUsageReports, _smContentAdmin } from "./SmContentAdmin";
import {
  _smSysAdmin,
  _manageUsers,
  _manageOrganizations,
  _seeAppLogs,
  _canManagePaymentPlans,
  _canManageLabels, 
  _canAssignUsersToLabels
} from "./SmSysAdmin";
import {
  _canDownload,
  _canDownloadHighres,
  _canDownloadMp3,
  _canPreview,
  _clearanceExemptions,
  _mlPlayer,
} from "./MlPlayer";
import {
  _mlLibraries,
  _libraryAdvSearchSettings,
  _librarySearchHistory,
  _libraryResultView,
  _libraryAdvSearchType,
  _libraryRankingType,
  _libraryExcludeRestricted,
  _libraryCanSeeAllContent
} from "./MlLibraries";
import {
  _mlAlbums,
  _albumAdvSearchSettings,
  _albumSearchHistory,
  _albumResultView,
  _albumAdvSearchType,
  _albumRankingType,
} from "./MlAlbumSearch";
import {
  _mlPlaylists,
  _mlPlPromoAdmin,
  _mlPlaylistsAdd,
  _mlPlaylistsShare,
  _mlPlaylistsDownload,
  _mlPlaylistApplyStatuses,
} from "./MlPlaylists";
import {
  _mlContentAdmin,
  _mlContentCanExplicitContent,
  _mlContentCanManagePreRelease,
  _mlContentCanManageRestricted,
  _mlContentCanCreateAdminNotes,
  _mlContentCanDeleteTracks,
  _mlContentCanEditTracksAndAlbums,
  _mlContentCanArchive,
} from "./MlContentAdmin";
import {
  _mlAdmin,
  _manageMlUsers,
  _manageMlGroups,
  _manageMlRoles,
  _manageMlSettings,
  _manageMlTrackRank,
  _manageMlAlbumRank,
  _manageMlPlaylistCategory,
  _manageMlActivityLimit,
  _manageMlHelp,
  _manageMlTemplates,
} from "./MlAdmin";
import { _smLog, _canSeeLog } from "./SmLog";
import { _smReports, _canSeeReports } from "./SmReports";
import {
  _settingSearch,
  _settingSearchShowAllFields,
  _settingSearchShowAllTagTypes,
  _settingSearchAllFields,
  _settingSearchAllTagTypes,
  _settingSearchOrigin,
  _settingSearchFields,
  _settingSearchSingleViewCols,
  _settingMusicSearch,
  _settingSearchShowGlobalQ,
  _settingAllKeyFields,
} from "./SettingMusicSearch";

import {
  _settingAlbumSearch,
  _settingAlbumSearchShowAllFields,
  _settingAlbumSearchShowAllTagTypes,
  _settingAlbumSearchAllFields,
  _settingAlbumSearchAllTagTypes,
  _settingAlbumSearchOrigin,
  _settingAlbumSearchFields,
  _settingAlbumSearchSingleViewCols,
  _settingAlbumMusicSearch,
  _settingAlbumSearchShowGlobalQ,
  _settingAlbumAllKeyFields,
} from "./SettingAlbumMusicSearch";

import {
  _settingMusicResult,
  _settingMusicResultSingleViewCols,
  _settingMusicResultMultiViewCols,
  _settingMusicResultRankingType,
} from "./SettingMusicResult";

import {
  _settingAlbumMusicResult,
  _settingAlbumMusicResultSingleViewCols,
  _settingAlbumMusicResultMultiViewCols,
  _settingAlbumMusicResultRankingType,
} from "./SettingAlbumMusicResult";

import {
  _settingMusicSearchPref,
  _settingSearchShowUserPref,
  _settingSearchUserPrefCanSave,
  _settingSearchUserPrefFields,
} from "./SettingMusicSearchUserPref";

import {
  _settingAlbumMusicSearchPref,
  _settingAlbumSearchShowUserPref,
  _settingAlbumSearchUserPrefCanSave,
  _settingAlbumSearchUserPrefFields,
} from "./SettingAlbumMusicSearchUserPref";

import {
  _settingMetadataConditional,
  _settingMetadataFieldsDefault,
  _settingMetadataFieldsCommissioned,
  _settingMetadataFieldsLibrary,
  _settingMetadataFieldsCommercial,
  _settingMetadata,
} from "./SettingMusicMetadata";

import {
  _mlLicensingCanCreate,
  _mlLicensingCanRunWizard,
  _mlLicensingFormAll,
  _mlLicensingTrackAll,
  _mlLicensingOverideRestrictions,
  _mlLicensing,
  _mlLicensingWorkflowAdmin,
  _mlLicensingWorkflowPermission,
  _mlLicensingCanAddToPPL,
  _mlLicensingCanShareCF,
} from "./MlLicensing";
import {
  _mlLicensingAdmin,
  _manageMlLiStatuses,
  _manageMlLiParams,
  _manageMlLiRules,
  _canRaiseContentAlert,
} from "./MlLicensingAdmin";
import {
  _albumMetadataConfig,
  _mlMetadata,
  _trackMetadataConfig,
  _canSeeAdminNotes,
  _canDownloadMetadataXML,
  _canMatchWithPRS,
} from "./MlMetadata";
import {
  _settingAlbumMetadata,
  _settingAlbumMetadataConditional,
  _settingAlbumMetadataFieldsDefault,
  _settingAlbumMetadataFieldsLibrary,
  _settingAlbumMetadataFieldsCommercial,
  _settingAlbumMetadataFieldsCommissioned,
} from "./SettingAlbumMetadata";
import { _uploads } from "./Uploads";

import {
  _Messages,
  _canAddContent,
  _canCreateContentRequest,
  _canRequestResponse,
} from "./Messages";

import { _playouts } from "./playout";
import { _canCreateContentAlert, _contentRequest } from "./ContentTrackerPermit";
import { _smEmailAdmin } from "./SmEmailAdmin";
import { _templates } from "./Templates";

export default [
  _smEmailAdmin, 
  _smSysAdmin,
  _manageUsers,
  _seeAppLogs,
  _manageOrganizations,
  _canManagePaymentPlans,
  _canManageLabels, 
  _canAssignUsersToLabels,
  

  _smContentAdmin,
  _manageUsageReports,

  _mlContentAdmin,
  _mlContentCanExplicitContent,
  _mlContentCanManagePreRelease,
  _mlContentCanManageRestricted,
  _mlContentCanCreateAdminNotes,
  _mlContentCanDeleteTracks,
  _mlContentCanEditTracksAndAlbums,
  _mlContentCanArchive,

  _uploads,
  _templates, 

  _mlAdmin,
  _manageMlUsers,
  _manageMlGroups,
  _manageMlRoles,
  _manageMlSettings,
  _manageMlTrackRank,
  _manageMlAlbumRank,
  _manageMlPlaylistCategory,
  _manageMlActivityLimit,
  _manageMlHelp,

  _mlMetadata,
  _trackMetadataConfig,
  _albumMetadataConfig,
  _canSeeAdminNotes,
  _canDownloadMetadataXML,
  _canMatchWithPRS, 

  _mlLibraries,
  _librarySearchHistory,
  _libraryResultView,
  _libraryAdvSearchType,
  _libraryRankingType,
  _libraryExcludeRestricted,
  _libraryCanSeeAllContent,

  _mlAlbums,
  _albumSearchHistory,
  _albumResultView,
  _albumAdvSearchType,
  _albumRankingType,

  _settingMusicSearch,
  _settingSearchShowGlobalQ,
  _settingSearchAllFields,
  _settingSearchShowAllFields,
  _settingSearchAllTagTypes,
  _settingSearchShowAllTagTypes,
  _settingSearchOrigin,
  _settingSearchFields,
  _settingAllKeyFields,

  _settingAlbumMusicSearch,
  _settingAlbumSearchShowGlobalQ,
  _settingAlbumSearchAllFields,
  _settingAlbumSearchShowAllFields,
  _settingAlbumSearchAllTagTypes,
  _settingAlbumSearchShowAllTagTypes,
  _settingAlbumSearchOrigin,
  _settingAlbumSearchFields,
  _settingAlbumAllKeyFields,

  _settingMusicResult,
  _settingMusicResultSingleViewCols,
  _settingMusicResultMultiViewCols,
  _settingMusicResultRankingType,

  _settingAlbumMusicResult,
  _settingAlbumMusicResultSingleViewCols,
  _settingAlbumMusicResultMultiViewCols,
  _settingAlbumMusicResultRankingType,

  _settingMusicSearchPref,
  _settingSearchShowUserPref,
  _settingSearchUserPrefFields,
  _settingSearchUserPrefCanSave,

  _settingAlbumMusicSearchPref,
  _settingAlbumSearchShowUserPref,
  _settingAlbumSearchUserPrefFields,
  _settingAlbumSearchUserPrefCanSave,

  _settingMetadata,
  _settingMetadataConditional,
  _settingMetadataFieldsDefault,
  _settingMetadataFieldsCommissioned,
  _settingMetadataFieldsLibrary,
  _settingMetadataFieldsCommercial,

  _settingAlbumMetadata,
  _settingAlbumMetadataConditional,
  _settingAlbumMetadataFieldsDefault,
  _settingAlbumMetadataFieldsLibrary,
  _settingAlbumMetadataFieldsCommercial,
  _settingAlbumMetadataFieldsCommissioned,

  _mlPlaylists,
  _mlPlPromoAdmin,
  _mlPlaylistsAdd,
  _mlPlaylistsShare,
  _mlPlaylistsDownload,
  _mlPlaylistApplyStatuses,

  _mlPlayer,
  _canDownloadMp3,
  _canDownloadHighres,
  _canPreview,
  _clearanceExemptions,

  _smLog,
  _canSeeLog,

  _smReports,
  _canSeeReports,

  _Messages,
  _canAddContent,
  _canCreateContentRequest,
  _canRequestResponse,

  _mlLicensingAdmin,
  _manageMlLiStatuses,
  _manageMlLiParams,
  _manageMlLiRules,
  _canRaiseContentAlert,

  _mlLicensing,
  _mlLicensingCanCreate,
  _mlLicensingCanRunWizard,
  _mlLicensingFormAll,
  _mlLicensingTrackAll,
  _mlLicensingOverideRestrictions,
  // _mlLicensingWorkflowAdmin,
  _mlLicensingWorkflowPermission,
  _mlLicensingCanAddToPPL,
  _mlLicensingCanShareCF,

  _playouts,
  _contentRequest,
  _canCreateContentAlert,
];
