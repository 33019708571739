import React, { cloneElement, Component } from "react";
import TableCell from "@material-ui/core/TableCell";
import { Avatar, Checkbox } from "@material-ui/core";
import {
  ColumnStickyTypes,
  ColumnTypes,
  JustificationTypes,
} from "./table-view/TableTypes";
import PlayerRef from "../modules/ml-player/PlayerRef/PlayerRef";
import Moment from "react-moment";
import { getAriaLabelForTableRowCheckBox } from "./Accessibility";
import HighlightedText from "./HighlightedText";
import { PageTableType } from "../redux/Types";
import { AuthSrc } from "./AuthSrc";
import moment from "moment";

class TableDataCol extends Component {
  cellContentRender() {
    const {
      classes,
      column,
      row,
      rowId,
      selected,
      disabled,
      postUpdating,
      allUsers,
    } = this.props;
    switch (column.type) {
      case ColumnTypes.player:
        if (column.cellRenderer) return column.cellRenderer(row, this.props);
        return postUpdating ? (
          <span style={{ fontSize: "9px", color: "yellow" }}>...</span>
        ) : (
          <PlayerRef key={rowId} aria-label={column.label} item={row} />
        );
      case ColumnTypes.thumb:
        if (column.cellRenderer) return column.cellRenderer(row, this.props);
        return (
          <Avatar
            title={this.getAvatarTitle(column.tablePageType, row)}
            alt={
              row[column.alt] ||
              row["title"] ||
              row["name"] ||
              row["track_name"] ||
              row["album_name"] ||
              row["created_user"] ||
              "-"
            }
            variant={column.variant || "square"}
            className={classes.tableArtwork}
            src={
              column.tablePageType == PageTableType.ADMIN_USERS ||
              column.tablePageType == PageTableType.ADMIN_GROUPS ||
              PageTableType.UPLOAD_TRACKS
                ? row[column.field]
                : AuthSrc(row[column.field])
            }
          >
            {(
              row[column.alt] ||
              row["title"] ||
              row["name"] ||
              row["track_name"] ||
              row["album_name"] ||
              row["created_user"] ||
              "-"
            )
              ?.charAt(0)
              ?.toUpperCase()}
          </Avatar>
        );

      case ColumnTypes.playerAndThumb:
        const { artworkProps, playerProps } = column.cellProp
          ? column.cellProp(row, this.props)
          : {};
        return (
          <>
            <div tabIndex="-1" class="albumEnable thumbPlayer">
              <Avatar
                title={
                  artworkProps?.title
                    ? artworkProps.title
                    : this.getAvatarTitle(column.tablePageType, row)
                }
                alt={
                  artworkProps?.alt
                    ? artworkProps.alt
                    : row[column.alt] ||
                      row["title"] ||
                      row["name"] ||
                      row["created_user"] ||
                      "-"
                }
                variant={
                  artworkProps?.variant
                    ? artworkProps.variant
                    : column.variant || "square"
                }
                className={classes.tableArtwork}
                src={AuthSrc(
                  artworkProps?.src
                    ? artworkProps.src
                    : row[column.artworkField]
                )}
              >
                {(artworkProps?.alt
                  ? artworkProps.alt
                  : row[column.alt] ||
                    row["title"] ||
                    row["name"] ||
                    row["created_user"] ||
                    "-"
                )
                  ?.charAt(0)
                  ?.toUpperCase()}
              </Avatar>
              <div tabIndex="-1" class="playTrack">
                {column.cellRenderer ? (
                  column.cellRenderer(row, this.props)
                ) : playerProps?.postUpdating || postUpdating ? (
                  <span
                    class="pUpdating"
                    style={{ fontSize: "9px", color: "yellow" }}
                  >
                    ...
                  </span>
                ) : (
                  <PlayerRef
                    key={playerProps?.key ? playerProps.key : rowId}
                    ariaLabel={
                      playerProps?.ariaLabel
                        ? playerProps.ariaLabel
                        : column.label
                    }
                    item={playerProps?.item ? playerProps?.item : row}
                    isMlAdded={
                      playerProps?.mlCreated ? playerProps?.mlCreated : false
                    }
                    popoutFor={
                      column.tablePageType == PageTableType.SEARCH_TRACKS?PageTableType.SEARCH_TRACKS:(column.tablePageType == PageTableType.SEARCH_ALBUM_TRACKS?PageTableType.SEARCH_ALBUM_TRACKS:(column.tablePageType == PageTableType.CLEARANCE_TRACKS?PageTableType.CLEARANCE_TRACKS:(column.tablePageType == PageTableType.CLEARANCE_FORM_TRACKS?PageTableType.CLEARANCE_FORM_TRACKS:null))) 
                    }
                  />
                )}
              </div>
            </div>
          </>
        );
      case ColumnTypes.menu:
        const { contextActions } = this.props;
        return cloneElement(contextActions, { ...this.props });
      case ColumnTypes.selector:
        const handleSelectClick = (event) => {
          const {
            rowId,
            data: { selectedItems },
            selectItems,
          } = this.props;
          let newSelecteds = [...selectedItems];
          if (event.target.checked) newSelecteds.push(rowId);
          else {
            const index = newSelecteds.findIndex((v) => v === rowId);
            newSelecteds.splice(index, 1);
          }
          selectItems(newSelecteds);
        };
        return (
          <Checkbox
            checked={selected}
            onChange={handleSelectClick}
            onClick={(e) => e.stopPropagation()}
            aria-label={
              column.tablePageType && row.name
                ? getAriaLabelForTableRowCheckBox(
                    column.tablePageType,
                    row.name
                  )
                : null
            }
            inputProps={{
              "aria-label": `${
                column.tablePageType && row.name
                  ? getAriaLabelForTableRowCheckBox(
                      column.tablePageType,
                      row.name
                    )
                  : null
              }`,
            }}
          />
        );
      case ColumnTypes.content_admin_selector:
        const handleContentAdminSelectClick = (event) => {
          const {
            rowId,
            data: { selectedItems },
            selectItems,
          } = this.props;
          let newSelecteds = [...selectedItems];
          if (event.target.checked) newSelecteds.push(rowId);
          else {
            const index = newSelecteds.findIndex((v) => v === rowId);
            newSelecteds.splice(index, 1);
          }
          selectItems(newSelecteds);
        };
        return (
          <Checkbox
            checked={selected}
            disabled={disabled(row)}
            onChange={handleContentAdminSelectClick}
            onClick={(e) => e.stopPropagation()}
            aria-label={
              column.tablePageType && row.name
                ? getAriaLabelForTableRowCheckBox(
                    column.tablePageType,
                    row.name
                  )
                : null
            }
            inputProps={{
              "aria-label": `${
                column.tablePageType && row.name
                  ? getAriaLabelForTableRowCheckBox(
                      column.tablePageType,
                      row.name
                    )
                  : null
              }`,
            }}
          />
        );

      case ColumnTypes.options: {
        const { accessDataProps } = column.cellProp
          ? column.cellProp(row, this.props)
          : {};
        if (column.cellRenderer) return column.cellRenderer(row, this.props);

        var textV = column.filterOptions?.find(
          (r) => r.id == row[column.field]
        )?.label;  
        
        var ariaLabelV = accessDataProps?.title? accessDataProps?.title : textV;

        return (
          <span
            aria-label={
              accessDataProps && accessDataProps.ariaLabel
                ? `${accessDataProps.ariaLabel} ${ariaLabelV || row[column.field]}`
                : null
            }
            title={ariaLabelV || row[column.field]}
          >
            {textV || HighlightedText(row, column.field)}
          </span>
        );
      }
      case ColumnTypes.users: {
        const { accessDataPropsUsers } = column.cellProp
          ? column.cellProp(row, this.props)
          : {};
        if (column.cellRenderer) return column.cellRenderer(row, this.props);
        var textUsers = allUsers?.find((r) => r.userId === row[column.field])?.name;
        return (
          <span
            aria-label={
              accessDataPropsUsers && accessDataPropsUsers.ariaLabel
                ? `${accessDataPropsUsers.ariaLabel} ${textUsers || row[column.field]}`
                : null
            }
            title={textUsers || row[column.field]}
          >
            {textUsers || HighlightedText(row, column.field)}
          </span>
        );
      }
      case ColumnTypes.datetime:
        let dtValue = row[column.field];
        if (column.cellRenderer) return column.cellRenderer(row, this.props);
        else
          return dtValue ? (
            <Moment
              format="DD/MM/YYYY h:mm:ss A"
              titleFormat="DD/MM/YYYY h:mm:ss A"
              withTitle
            >
              {dtValue}
            </Moment>
          ) : null;

      case ColumnTypes.date:
        let dValue = row[column.field];
        if (column.cellRenderer) return column.cellRenderer(row, this.props);
        else
          return dValue ? (
            <Moment format="DD/MM/YYYY" titleFormat="DD/MM/YYYY" withTitle>
              {dValue}
            </Moment>
          ) : null;

      case ColumnTypes.boolean:
        let boolV = row[column.field];
        if (column.cellRenderer) return column.cellRenderer(row, this.props);
        else
          return boolV === true
            ? "True"
            : boolV === false
            ? "False"
            : !boolV
            ? ""
            : boolV;

      default:
        if (column.cellRenderer) return column.cellRenderer(row, this.props);
        return HighlightedText(row, column.field);
    }
  }

  getTitleForOptions(value, column) {
    var textV = column.filterOptions?.find((r) => r.id === value)?.label;
    return textV || value;
  }

  getCellTitle(value, column) {
    if (column.field === "trackDetails") {
      return null;
    }
    if (Array.isArray(value)) {
      var options = value;
      if (column.type === ColumnTypes.options) {
        options = value.map((r) => this.getTitleForOptions(value, column));
      }
      return options.join(", ");
    } else {
      return column.type === ColumnTypes.options
        ? this.getTitleForOptions(value, column)
        : value;
    }
  }

  getAvatarTitle(pageType, row) {
    switch (pageType) {
      case PageTableType.SEARCH_TRACKS:
      case PageTableType.SEARCH_ALBUM_TRACKS:
      case PageTableType.MYPLAYLIST_TRACKS:
        return row?.trackTitle;
      case PageTableType.SEARCH_ALBUMS:
        return row?.prodName;
      case PageTableType.MYPLAYLIST:
      case PageTableType.ADMIN_USERS:
        return row?.name;
      case PageTableType.UPLOAD_TRACKS:
        return row?.created_user;
      default:
        return null;
    }
  }

  render() {
    const { column, row } = this.props;
    const { accessDataProps } = column.cellProp
      ? column.cellProp(row, this.props)
      : {};

    //Converting to time format if field is duration
    var titleValue =
      column.field == "duration"
        ? moment
            .duration(row[column.field], "seconds")
            .format("h:mm:ss", { trim: false }) || ""
        : accessDataProps && accessDataProps.title
        ? accessDataProps.title
        : this.getCellTitle(row[column.field], column);

    const cellProps = {
      title: titleValue,
      align: column.alignCell || JustificationTypes.left,
      key: column.field,
      width: column.width,
      ...(column.sticky === ColumnStickyTypes.right && {
        style: {
          position: "sticky",
          right: 0,
          zIndex: 2,
        },
      }),
      ...(column.sticky === ColumnStickyTypes.left && {
        style: {
          position: "sticky",
          left: 0,
          zIndex: 2,
        },
      }),
    };
    return (
      <TableCell
        className={column.cursor ? column.cursor : null}
        {...cellProps}
      >
        {this.cellContentRender()}
      </TableCell>
    );
  }
}

export default TableDataCol;
