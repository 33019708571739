import axios from "axios";
import store from "redux/Store";
import { __trackStatus } from "../../_clearance/definition/status";
import { __compileRules, __getParam } from "../../_clearance/utils/utils";
import ParamObjectTypes from "_clearance/definition/ParamObjectTypes";
import trackCTags from "_module/definition/trackCTags";
module = "/clearanceRuntime";
export const actionTypes = {
  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,
  ERROR: "ERROR" + module,
  CTAG: "CTAG" + module,
  CTAG_ERROR: "CTAG_ERROR" + module,
  RESET_CTAG: "RESET_CTAG" + module,
  SET_COPY_LIVE_IDS: "SET_COPY_LIVE_IDS" + module,
  REMOVE_COPY_LIVE_IDS: "REMOVE_COPY_LIVE_IDS" + module,
};

export const loadRuntime = (ruleCollection) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  dispatch({
    type: actionTypes.LOADING,
  });

  const cTagGroupOrder = [3, 2, 1];

  let _r = {
    _rules: null,
    _statuses: [],
    _params: [],
  };

  axios
    .get(`${orgID}/licensingruntime/${ruleCollection ? "nr" : "all"}`)
    .then((res) => {
      _r._rules = ruleCollection
        ? ruleCollection
        : __compileRules(res.data._rules, res.data._statuses);

      _r._statuses = [
        ...res.data._statuses,
        __trackStatus.norule,
        __trackStatus.inprogress,
      ];

      _r._params = [...res.data._params];

      if (_r._rules?.length && _r._params?.length && _r._statuses?.length) {
        // sort parameters by group of ctag type
        _r._params = _r._params.sort((a, b) => {
          let _actag_group, _bctag_group = undefined;
          let _ap, _bp = undefined;
          if(a && a.parent == ParamObjectTypes.POT_TrackCTag){
            _ap = a;
          }
          if(b && b.parent == ParamObjectTypes.POT_TrackCTag){
            _bp = b;
          }
          if (_ap == undefined && _bp == undefined) return 0;
          if (_ap == undefined) return -1;
          if (_bp == undefined) return 1;
          _actag_group = trackCTags[_ap.ctag]?.group;
          _bctag_group = trackCTags[_bp.ctag]?.group;
          return cTagGroupOrder.indexOf(_actag_group) - cTagGroupOrder.indexOf(_bctag_group);
        })
        // sort rules by group of ctag type
        _r._rules = _r._rules.sort((a, b) => {
          let _actag_group, _bctag_group = undefined;
          let _ap, _bp = undefined;
          for (var iii = 0; iii < a.trigger.length; iii++) {
            let d = a.trigger[iii];
            let _p = __getParam(d.param, _r._params);
            if(_p && _p.parent == ParamObjectTypes.POT_TrackCTag){
              _ap = _p;
              break;
            }
          }
          for (var iii = 0; iii < b.trigger.length; iii++) {
            let d = b.trigger[iii];
            let _p = __getParam(d.param, _r._params);
            if(_p && _p.parent == ParamObjectTypes.POT_TrackCTag){
              _bp = _p;
              break;
            }
          }
          if (_ap == undefined && _bp == undefined) return 0;
          if (_ap == undefined) return -1;
          if (_bp == undefined) return 1;
          _actag_group = trackCTags[_ap.ctag]?.group;
          _bctag_group = trackCTags[_bp.ctag]?.group;
          return cTagGroupOrder.indexOf(_actag_group) - cTagGroupOrder.indexOf(_bctag_group);
        })
        dispatch({
          type: actionTypes.LOAD,
          payload: _r,
        });
      } else {
        dispatch({
          type: actionTypes.ERROR,
          payload: { e: "runtimeError" },
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
    });
};

export const resetCatg = () => (dispatch) => {
  dispatch({
    type: actionTypes.RESET_CTAG,
  });
};

export const setCopyLiveIds = (copyLiveIds) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_COPY_LIVE_IDS,
    payload: copyLiveIds,
  });
};

export const removeCopyLiveIds = (id) => (dispatch) => {
  dispatch({
    type: actionTypes.REMOVE_COPY_LIVE_IDS,
    payload: id,
  });
};
