    import { textLengths } from "../../redux/Types";

const getMatchedErrorList = (regex, content, validations)=>{
    let errorList = [];
    let matches = content.match(regex);
    for(let i = 0;i<validations?.length;i++){
        let variable = validations[i];
        if(!matches || matches?.indexOf(variable) === -1){
            errorList.push(`Variable ${variable} is missing`);
        }
    }
    return errorList;
}

export const ValidateEmailTemplate = (emailTemplate) => {
    let errors = {}

    let validationConfig = {
        // USER_INVITE_EMAIL_TEMPLATE_ID
        "09ea2bc2-a033-41a7-9954-c6fff9ff0098": {
            subjectVariableList: ['{{ORG_NAME}}'], 
            templateVariableList: ['{{NAME}}', '{{INVITATION_EXPIRES}}', '{{LINK}}', '{{CONTACT_PHONE}}'], 
        }, 
        // USER_BBC_INVITE_EMAIL_TEMPLATE_ID
        "96e4652b-eb02-48e1-8039-cbebd4df5463": {
            subjectVariableList: ['{{ORG_NAME}}'], 
            templateVariableList: ['{{NAME}}', '{{LINK}}', '{{CONTACT_PHONE}}'], 
        }, 
        // TWOFA_EMAIL_TEMPLATE_ID
        "c68d94e6-2080-4bc8-972c-9dec65ac793f": {
            subjectVariableList: [], 
            templateVariableList: ['{{TOKEN}}', '{{CONTACT_PHONE}}'], 
        }, 
        // ACTIVITY_LIMITATION_EMAIL_TEMPLATE_ID
        "64ec500d-e2ed-49c5-88d1-e9218129fbe8": {
            subjectVariableList: [], 
            templateVariableList: ['{{NAME}}', '{{MESSAGE}}', '{{CONTACT_PHONE}}'], 
        }, 
        // LOCK_ACCOUNT_EMAIL_TEMPLATE_ID
        "79fa5dd0-3bc2-4242-92c8-001369ed12ce": {
            subjectVariableList: [], 
            templateVariableList: ['{{REASON}}', '{{CONTACT_PHONE}}'], 
        }, 
        // INVITATION_ACCEPTED_EMAIL_TEMPLATE_ID
        "6981b86c-ab14-4887-bf65-668355e7fa09": {
            subjectVariableList: [], 
            templateVariableList: ['{{LINK}}', '{{CONTACT_PHONE}}'], 
        }, 
        // NEW_DEVICE_LOGIN_EMAIL_TEMPLATE_ID
        "e1100eb7-8773-42ed-acf7-8e9230fd4c8f": {
            subjectVariableList: [], 
            templateVariableList: ['{{DEVICE_DETAILS}}', '{{MORE_INFO}}', '{{CONTACT_PHONE}}'], 
        }, 
        // RESET_PASSWORD_EMAIL_TEMPLATE_ID
        "7c1b8234-ebfc-48cc-9816-4a275326b81f": {
            subjectVariableList: [], 
            templateVariableList: ['{{RESET_PASSWORD_LINK}}', '{{CONTACT_PHONE}}'], 
        }, 
        // ACCOUNT_SUSPEND_EMAIL_TEMPLATE_ID
        "4833eef2-b99e-40d4-9d9d-89d549b9873d": {
            subjectVariableList: [], 
            templateVariableList: ['{{CONTACT_PHONE}}'], 
        }, 
        // PLAYLIST_SHARING_LIMIT_REACHED_EMAIL_TEMPLATE_ID
        "787c206e-5fb4-4875-ab7b-4264001417aa": {
            subjectVariableList: [], 
            templateVariableList: ['{{CONTACT_PHONE}}'], 
        }, 
        // EXPIRED_ACCOUNT_SUSPEND_EMAIL_TEMPLATE_ID
        "18425d8f-d4c4-4036-938e-31bf3b241419": {
            subjectVariableList: ['{{DAYS}}'], 
            templateVariableList: ['{{DAYS}}', '{{DAYS}}', '{{CONTACT_PHONE}}'], 
        }, 
        // INACTIVE_ACCOUNT_SUSPEND_EMAIL_TEMPLATE_ID
        "70c8194b-b233-492e-9eb7-b8f50d0b56e8": {
            subjectVariableList: ['{{DAYS}}'], 
            templateVariableList: ['{{DAYS}}', '{{DAYS}}', '{{CLIENT_ENDPOINT}}', '{{CONTACT_PHONE}}'], 
        }, 
        // CONTENT_REQUEST_CREATED_EMAIL_TEMPLATE_ID
        "0d7ae8b4-2bee-4d24-a7a3-7cbda519e710": {
            subjectVariableList: ['{{REQUEST_PRIORITY}}', '{{REQUEST_TYPE}}'], 
            templateVariableList: ['{{BY_NAME}}', '{{COMPANY}}', '{{PRIORITY_LEVEL}}', '{{PROPOSED_USAGE}}', '{{TYPE}}', '{{TITLE}}', '{{CONTENT_TYPE}}', '{{CONTENT_TYPE_VALUE}}', '{{ALBUM}}', '{{CATALOG}}', '{{RECORD_LABEL}}', '{{PUBLISHER}}', '{{ALTERNATE_TRACK_TITLE}}', '{{DISTRIBUTOR_NAME}}', '{{NOTES}}', '{{REFERENCE_NUMBER}}', '{{REQUESTED_BY}}', '{{REQUESTED_DATE}}', '{{LAST_EDITED}}', '{{LAST_EDITED_BY}}'], 
        }, 
        // CONTENT_REQUEST_UPDATED_EMAIL_TEMPLATE_ID
        "773d3aea-2b5d-4289-af4f-eaddefe030d3": {
            subjectVariableList: ['{{CONTENT_TITLE}}'], 
            templateVariableList: ['{{CONTENT_TITLE}}', '{{UPDATED_BY_NAME}}', '{{STATUS_BACKGROUND_COLOR}}', '{{STATUS_LABEL}}', '{{STATUS_NOT_SOURCED}}', '{{PRIORITY_LEVEL}}', '{{PROPOSED_USAGE}}', '{{TYPE}}', '{{TITLE}}', '{{CONTENT_TYPE}}', '{{CONTENT_TYPE_VALUE}}', '{{ALBUM}}', '{{CATALOG}}', '{{RECORD_LABEL}}', '{{PUBLISHER}}', '{{ALTERNATE_TRACK_TITLE}}', '{{DISTRIBUTOR_NAME}}', '{{NOTES}}', '{{REFERENCE_NUMBER}}', '{{REQUESTED_BY}}', '{{REQUESTED_DATE}}', '{{LAST_EDITED}}', '{{LAST_EDITED_BY}}'], 
        }, 
        // CLEARANCE_STAUTS_CHANGE_EMAIL_TEMPLATE_ID
        "97179b93-7243-4d2e-99b4-bb12275731dd": {
            subjectVariableList: ['{{CLEARANCE_FORM_NAME}}', '{{GENRE}}', '{{SERVICE_GROUP}}', '{{SERVICE}}', '{{STATUS}}'], 
            templateVariableList: ['{{CLEARANCE_FORM_NAME}}', '{{REF_NO}}', '{{SERVICE}}', '{{RIGHTS_CONTACT}}', '{{GENRE}}', '{{STATUS}}', '{{CREATED}}', '{{SERVICE_GROUP}}', '{{DEADLINE_DATE}}', '{{TRACK_ROWS}}', '{{SERVICE_GROUP_DETAILS}}'], 
        }, 
    }

    if (emailTemplate) {
        // let regex = /\{\{[^}]+\}\}/g;
        let regex = /\{\{[^{}]*\}\}/g;

        // ====
        let htmlContent = emailTemplate.template;
        let errorListTemplate = getMatchedErrorList(regex, htmlContent, validationConfig[emailTemplate.emailId]?.templateVariableList)
        if(errorListTemplate.length>0){
            errors["template"] = errorListTemplate;
        }
        // ====
        let subjectContent = emailTemplate.subject;
        let errorListSubject = getMatchedErrorList(regex, subjectContent, validationConfig[emailTemplate.emailId]?.subjectVariableList)
        if(errorListSubject.length>0){
            errors["subject"] = errorListSubject;
        }
        // ====
        if (!emailTemplate.title) {
            errors.title = "Title should not be empty";
        } 
        else if (emailTemplate.title && emailTemplate.title.length > textLengths.MEDIUMTEXT) {
            errors.title = "Sorry, the title you entered is too long. Please try a shorter title";
        }
        if (!emailTemplate.subject) {
            errors.subject = "Subject should not be empty";
        } 
        else if (emailTemplate.subject && emailTemplate.subject.length > textLengths.MEDIUMTEXT) {
            errors.subject = "Sorry, the subject you entered is too long. Please try a shorter subject";
        }
    }
    


    if (Object.entries(errors).length === 0) {
        return null;
    } else {
        return errors;
    }
}