import axios from "axios";
import store from "redux/Store";
import { v4 as uuidv4 } from "uuid";
import { CLEAR_DOWNLOAD_LIST, OriginTypes, pagingTotalRelation } from "redux/Types";
import { userPrefType } from "../Types";
import DiscreteConfig from "../../modules/ml-music-search/track/DiscreteConfig";
import { deepCopyFunction } from "util/CommonFunc";
import trackFields from "_module/definition/trackFields";
import { TerritoryList } from "util/table-view/TableTypes";
var module = "/mlTracks/library/";

var searchTransId = null;

export const actionTypes = {
  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,
  CLEAR: "CLEAR" + module,
  ERROR: "ERROR" + module,
  FOCUS_ITEM: "FOCUS_ITEM" + module,
  SELECT_ITEMS: "SELECT_ITEMS" + module,
  QUERY: "QUERY" + module,
  RESULT_VIEW: "RESULT_VIEW" + module,
  ADV_SEARCH: "ADV_SEARCH" + module,
  POSTING: "POSTING" + module,
  POST_OK: "POSTOK" + module,
  POST_ERROR: "POST_ERROR" + module,
  SET_MESSAGE: "SET_MESSAGE" + module,
  CLEAR_MESSAGE: "CLEAR_MESSAGE" + module,
  LOAD_QUERY: "LOAD_QUERY" + module,
  EDIT_ENABLE: "EDIT_ENABLE" + module,
  EDIT_ITEM: "EDIT_ITEM" + module,
  EMPTY_ITEM: "EMPTY_ITEM" + module,
  UPDATING: "UPDATING" + module,
  UPDATE_OK: "UPDATE_OK" + module,
  GETTING_UPDATE_DATA: "GETTING_UPDATE_DATA" + module,
  UPDATE_DATA_OK: "UPDATE_DATA_OK" + module,
  CLOUD_OPEN: "CLOUD_OPEN" + module,
  CLOUD_CLOSE: "CLOUD_CLOSE" + module,
  CLOUD_QUERY: "CLOUD_QUERY" + module,
  CLOUD_SEARCH: "CLOUD_SEARCH" + module,
  CLOUD_LOADING: "CLOUD_LOADING" + module,
  CLOUD_LOAD: "CLOUD_LOAD" + module,
  CLOUD_ERROR: "CLOUD_ERROR" + module,
  CLOUD_SET_PERS_FIELD: "CLOUD_SET_PERS_FIELD" + module,
  POST_UPDATE: "POST_UPDATE" + module,
  SWITCH_DISCRETE: "SWITCH_DISCRETE" + module,
  SET_SUGGESTIONS_LIST: "SET_SUGGESTIONS_LIST" + module,
  SHOW_CTAG_RULE: "SHOW_CTAG_RULE" + module,
  GET_CTAG_RULE: "GET_CTAG_RULE" + module,
  RETRIEVING_CTAG_RULE: "RETRIEVING_CTAG_RULE" + module,
  POST_UPDATE_TRACKINPUT: "POST_UPDATE_TRACKINPUT" + module,
  LOAD_TRACKINPUT: "LOAD_TRACKINPUT" + module,
  ERROR_TRACKINPUT: "ERROR_TRACKINPUT" + module,
  CLEAR_TRACKINPUT: "CLEAR_TRACKINPUT" + module,
  LOADING_TRACKINPUT: "LOADING_TRACKINPUT" + module,
  TRACK_INPUT_QUERY: "TRACK_INPUT_QUERY" + module,
  EMPTY_CTAG_RULE: "EMPTY_CTAG_RULE" + module,
  LOADING_PRS_SEARCH_TRACKINPUT: "LOADING_PRS_SEARCH_TRACKINPUT" + module,
  LOAD_HISTORY: "LOAD_HISTORY" + module,
  REMOVE_HISTORY: "REMOVE_HISTORY" + module,
  REMOVE_HISTORY_ALL: "REMOVE_HISTORY_ALL" + module,
};

export const getCtagRuleDetails = (item, tagType, done = null) => (
  dispatch
) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();
  dispatch({
    type: actionTypes.RETRIEVING_CTAG_RULE,
  });
  axios
    .post(`${orgID}/mladmin/ctags/getCtagRules`, item)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_CTAG_RULE,
        payload: res.data,
        tagtype: tagType,
      });

      if (done) done(res.data.conditions, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: { e: err },
      });

      if (done) done(null, err);
    });
};

export const showCtagRulePreview = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.SHOW_CTAG_RULE,
    payload: item,
  });
};

export const emptyCtagRulePreview = () => (dispatch) => {
  dispatch({
    type: actionTypes.EMPTY_CTAG_RULE,
  });
};

const groupBy = (tracks, keys, isEnabled) => {
  if (
    !isEnabled ||
    !keys ||
    keys.length < 1 ||
    !DiscreteConfig ||
    DiscreteConfig.length < 1
  ) {
    tracks.forEach((r, i) => {
      r.orderIndex = i;
      r.discretedTracks = undefined;
      r.discretedKeys = {};
      r.isDiscreteMaster = true;
    });
    return tracks;
  }

  let groupedTracks = tracks.reduce((grouped, track, index) => {
    track.orderIndex = index;
    track.discretedTracks = undefined;
    track.discretedKeys = {};
    track.isDiscreteMaster = true;
    for (let key of keys) {
      const config = DiscreteConfig[key];
      if (config && config.fields && config.fields.length > 0) {
        let keyValues = config.fields.map((k) => {
          let keyVal = track[k];
          if (Array.isArray(keyVal)) {
            keyVal = keyVal.filter((f) => f && f.trim()).join("|");
          }
          return keyVal ? String(keyVal)?.toLowerCase()?.trim() : null;
        });

        if (keyValues.every((r) => r)) {
          track.discretedKeys[config.id] = keyValues.filter((f) => f).join("|");
          if (track.discretedKeys[config.id]) {
            let groupedParant = grouped.find(
              (g) =>
                g.discretedKeys[config.id] === track.discretedKeys[config.id] &&
                (!g.discretedTracks ||
                  g.discretedTracks.every(
                    (h) => h.orderIndex !== track.orderIndex
                  ))
            );
            if (groupedParant) {
              if (groupedParant.discretedTracks) {
                groupedParant.discretedTracks.push(track);
                track.isDiscreteMaster = false;
              } else {
                groupedParant.discretedTracks = [track];
                track.isDiscreteMaster = false;
              }
            }
          }
        }
      }
    }
    if (track.isDiscreteMaster) {
      grouped.push(track);
    }
    return grouped;
  }, []);

  return Object.values(groupedTracks).sort((a, b) =>
    a.orderIndex > b.orderIndex ? 1 : -1
  );
};

// const groupBy = (tracks, keys) => {
//   if (!keys || keys.length < 1) {
//     return tracks;
//   }

//   let groupedTracks = tracks.reduce((grouped, track, index) => {
//     track.orderIndex = index;

//     let isDuplicated = false;

//     grouped.forEach((r) => {
//       for (let key of keys) {
//         let keyValue = track[key];
//         if (
//           keyValue &&
//           r[key] &&
//           (typeof r[key] === "string"
//             ? r[key].trim()?.toLowerCase() === keyValue?.trim()?.toLowerCase()
//             : r[key] === keyValue)
//         ) {
//           if (r.secondaryTracks) {
//             r.secondaryTracks.push(track);
//           } else {
//             r.secondaryTracks = [track];
//           }
//           isDuplicated = true;
//           break;
//         }
//       }
//     });

//     if (!isDuplicated) {
//       grouped.push(track);
//     }

//     return grouped;
//   }, []);

//   return Object.values(groupedTracks).sort((a, b) =>
//     a.orderIndex > b.orderIndex ? 1 : -1
//   );
// };

export const cloudOpen = () => (dispatch) => {
  dispatch({
    type: actionTypes.CLOUD_OPEN,
  });
};

export const cloudSetPersonalField = (field, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.CLOUD_SET_PERS_FIELD,
    payload: field,
  });

  if (done) done(true);
};

export const cloudClose = () => (dispatch) => {
  dispatch({
    type: actionTypes.CLOUD_CLOSE,
  });
};

export const cloudSetQuery = (query) => (dispatch) => {
  dispatch({
    type: actionTypes.CLOUD_QUERY,
    payload: query,
  });
};

export const cloudSearch = (query, mainQ = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  const tId = uuidv4();

  if (mainQ) {
    mainQ.cloudQuery = query;
    delete mainQ.cloudQuery["filters"];
  }

  dispatch({
    type: actionTypes.CLOUD_QUERY,
    payload: query,
  });

  dispatch({
    type: actionTypes.CLOUD_LOADING,
  });

  axios
    .post(`${orgID}/trackcloud/search`, mainQ ? mainQ : query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        dispatch({
          type: actionTypes.CLOUD_LOAD,
          payload: res.data.data,
        });
      }
    })
    .catch((err) => {
      var message = err?.response?.data?.e;
      dispatch({
        type: actionTypes.CLOUD_ERROR,
        payload: message,
      });
    });
};

export const SetSearchSuggList = (list) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SUGGESTIONS_LIST,
    payload: list,
  });
};

export const editItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.EDIT_ITEM,
    payload: item,
  });
};

export const RemoveTrack = (message, item, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  item.orgId = orgID;
  const tId = uuidv4();

  axios
    .post(`${orgID}/uploads/tracks/removeTrack`, item, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.POST_OK,
        payload: res.data,
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: { e: err },
      });

      if (done) done(null, err);
    });
};

export const updateTrack = (message, item, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();
  dispatch({
    type: actionTypes.UPDATING,
    payload: message,
  });

  axios
    .post(`${orgID}/tracks/updateTrack`, item)
    .then((res) => {
      dispatch({
        type: actionTypes.POST_OK,
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: { e: err },
      });

      if (done) done(null, err);
    });
};

export const removeExplicitRule = (message, item, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();
  dispatch({
    type: actionTypes.UPDATING,
    payload: message,
  });

  axios
    .post(`${orgID}/mladmin/ctags/removeExplicitRule`, item)
    .then((res) => {
      dispatch({
        type: actionTypes.POST_OK,
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: { e: err },
      });

      if (done) done(null, err);
    });
};

export const contentAlert = (message, item, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();
  dispatch({
    type: actionTypes.UPDATING,
    payload: message,
  });

  axios
    .post(`${orgID}/track/contentAlert`, item)
    .then((res) => {
      dispatch({
        type: actionTypes.POST_OK,
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: { e: err },
      });

      if (done) done(null, err);
    });
};

export const updateSearchUserPref = (pref, message, done = null) => (
  dispatch
) => {
  const {
    user: { id },
  } = store.getState();
  const tId = uuidv4();
  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(
      `${id}/userpref/update`,
      { type: userPrefType.SERACHPREF, pref: pref },
      {
        headers: { tid: tId },
      }
    )
    .then((res) => {
      dispatch({
        type: actionTypes.POST_OK,
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: { e: err },
      });

      if (done) done(null, err);
    });
};

export const setResultView = (view) => (dispatch) => {
  dispatch({
    type: actionTypes.RESULT_VIEW,
    payload: view,
  });
};

export const loadQuery = (query) => (dispatch) => {
  dispatch({
    type: actionTypes.LOAD_QUERY,
    payload: query,
  });
};

export const setAdvSearch = (advsearch) => (dispatch) => {
  dispatch({
    type: actionTypes.ADV_SEARCH,
    payload: advsearch,
  });
};

export const setQuery = (query, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.QUERY,
    payload: query,
  });

  if (done) done();
};

export const showMessage = (message) => (dispatch) => {
  if (message) {
    dispatch({
      type: actionTypes.SET_MESSAGE,
      payload: message,
    });
  } else {
    dispatch({
      type: actionTypes.CLEAR_MESSAGE,
    });
  }
};

export const clear = () => (dispatch) => {
  dispatch({
    type: actionTypes.CLEAR,
  });
};

export const selectItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.SELECT_ITEMS,
    payload: items,
  });
};

export const focusItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.FOCUS_ITEM,
    payload: item,
  });

  // Get Rule List of CTAGS
  const {
    mltracklibrary: { selectedItem, ctagRuleList },
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  if (
    selectedItem &&
    selectedItem.cTagList &&
    selectedItem.cTagList.length > 0
  ) {
    var cTagIds = [];
    selectedItem.cTagList.forEach((item) => {
      cTagIds.push(item.ctagId);
    });
    dispatch({
      type: actionTypes.RETRIEVING_CTAG_RULE,
    });

    axios
      .post(`${orgID}/mladmin/ctags/getCtagRules`, {
        trackId: selectedItem.id,
        cTagIds: cTagIds,
      })
      .then((res) => {
        dispatch({
          type: actionTypes.GET_CTAG_RULE,
          payload: res.data,
          tagtype: selectedItem.cTagList,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.POST_ERROR,
          payload: { e: err },
        });
      });
  } else {
    dispatch({
      type: actionTypes.GET_CTAG_RULE,
      payload: [],
      tagtype: null,
    });
  }
};

export const discreteSwitch = (isEnabled) => (dispatch) => {
  const {
    mltracklibrary: {
      originalItems,
      totalItems,
      totalRelation,
      query: { discreteFlags },
    },
  } = store.getState();

  dispatch({
    type: actionTypes.SWITCH_DISCRETE,
    payload: isEnabled,
  });

  let groupedData = groupBy(originalItems, discreteFlags, isEnabled);
  dispatch({
    type: actionTypes.LOAD,
    payload: {
      total: totalItems,
      items: groupedData,
      originalItems: originalItems,
      totalRelation: totalRelation,
    },
  });
};

export const discrete = (discreteFlags) => (dispatch) => {
  const {
    mltracklibrary: { discreteEnabled, originalItems, totalItems, totalRelation },
  } = store.getState();
  let groupedData = groupBy(originalItems, discreteFlags, discreteEnabled);
  dispatch({
    type: actionTypes.LOAD,
    payload: {
      total: totalItems,
      items: groupedData,
      originalItems: originalItems,
      totalRelation: totalRelation
    },
  });
};

export const showAutoMessage = (message) => (dispatch) => {
  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });
  setTimeout(() => {
    dispatch({
      type: actionTypes.POST_OK,
    });
  }, 3000);
};

export const search = (query, isSandBox = false) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
    mltracklibrary: {
      discreteEnabled,
      query: { discreteFlags },
    },
  } = store.getState();

  searchTransId = uuidv4();

  dispatch({
    type: actionTypes.QUERY,
    payload: query,
  });
  if (
    query ||
    (query && query.q) ||
    (query && query.origin !== OriginTypes.all) ||
    (query && query.filters && query.filters.length > 0) ||
    (query && query.all && query.all.q) ||
    (query && query.tagAll && query.All.q)
  ) {
    const options = {
      headers: {
        tid: searchTransId,
      },
    };
    dispatch({
      type: actionTypes.LOADING,
    });

    dispatch({ type: CLEAR_DOWNLOAD_LIST });


    axios
      .post(
        isSandBox ? "/librarysearchsandbox" : "/librarysearch",
        query,
        options
      )
      .then((res) => {
        if (res.data.tid === searchTransId) {
          if (res.data?.data.length > 0) {
            axios
              .post(
                `${orgID}/ctag/CheckExplicitCtag`,
                {
                  trackIdList: res.data.data.map((t) => t.id),
                },
                options
              )
              .then((post) => {
                if (post.data.tid === searchTransId) {
                  for (var item of res.data.data) {
                    let trackId = item.id;
                    item.cTagList = post.data.data.find(
                      (opt) => opt.trackId === trackId
                    )?.cTagList;
                  }
                  const {
                    mltracklibrary: { items, originalItems },
                  } = store.getState();
                  dispatch({
                    type: actionTypes.POST_UPDATE,
                    payload: {
                      items: items,
                      originalItems: originalItems,
                    },
                  });
                }
              })
              .catch((error) => {
                const {
                  mltracklibrary: { items, originalItems },
                } = store.getState();
                dispatch({
                  type: actionTypes.POST_UPDATE,
                  payload: {
                    items: items,
                    originalItems: originalItems,
                  },
                });
              });
            let groupedData = groupBy(
              res.data.data,
              discreteFlags,
              discreteEnabled
            );
            dispatch({
              type: actionTypes.LOAD,
              payload: {
                total: res.data.total,
                items: groupedData,
                originalItems: res.data.data,
                postUpdating: true,
                totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS
              },
            });
            dispatch({
              type: actionTypes.QUERY,
              payload: { ...query, searchId: res.data.searchId },
            });
          } else {
            dispatch({
              type: actionTypes.LOAD,
              payload: {
                total: 0,
                items: [],
                originalItems: [],
                postUpdating: false,
                totalRelation: pagingTotalRelation.EQUALS
              },
            });
            dispatch({
              type: actionTypes.QUERY,
              payload: { ...query, searchId: res.data.searchId },
            });
          }
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ERROR,
          payload: { e: err },
        });
      });
  } else {
    dispatch({
      type: actionTypes.CLEAR,
      payload: [],
    });
  }
};

export const removeTrackSearchkHistory = (trackId) => (dispatch) => {
  dispatch({
    type: actionTypes.REMOVE_HISTORY,
    payload: {
      trackId: trackId
    },
  });
};

export const removeTrackSearchkHistoryAll = () => (dispatch) => {
  dispatch({
    type: actionTypes.REMOVE_HISTORY_ALL,
    payload: {},
  });
};

export const getTrackHistory = (trackId, done = null) => (dispatch) => {
  //   if (done) done({}, null);
  // }
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  // dispatch({
  //   type: actionTypes.POSTING,
  //   payload: message,
  // });

  axios
    .post(`/libraryTrackHistory`, { trackId: trackId }, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      //if (res.data.tid === tId) {
      dispatch({
        type: actionTypes.LOAD_HISTORY,
        payload: {
          trackId: trackId,
          history: res.data
        }
      });

      if (done) done(res.data, null);
      //}
    })
    .catch((err) => {
      // dispatch({
      //   type: actionTypes.POST_ERROR,
      //   // payload: { e: err },
      //   payload: err.response?.data || { e: (err.message ? err.message : "Error in creating or updating. please retry") },
      // });

      // if (done) done(null, err.response?.data?.name == 'Duplicate Name'?err.response?.data?.name:err.message || "error");
    });
};

export const getTracks = (query, needCtags = true) =>
  new Promise((resolve, reject) => {
    const {
      user: {
        credentials: { orgID },
      },
    } = store.getState();

    if (
      query ||
      (query && query.q) ||
      (query && query.origin !== OriginTypes.all) ||
      (query && query.filters && query.filters.length > 0) ||
      (query && query.all && query.all.q) ||
      (query && query.tagAll && query.All.q)
    ) {
      const options = {
        headers: {},
      };

      axios.post("/librarysearch", query, options).then((res) => {
        if (res.data?.data.length > 0) {
          if (!needCtags) {
            resolve(res?.data?.data ? res.data.data : []);
          } else {
            axios
              .post(
                `${orgID}/ctag/CheckExplicitCtag`,
                {
                  trackIdList: res.data.data.map((t) => t.id),
                },
                options
              )
              .then((post) => {
                for (var item of res.data.data) {
                  let trackId = item.id;
                  item.cTagList = post.data.data.find(
                    (opt) => opt.trackId === trackId
                  )?.cTagList;
                }
                resolve(res?.data?.data ? res.data.data : []);
              })
              .catch((error) => {
                resolve([]);
              });
          }
        } else {
          resolve([]);
        }
      });
    }
  });

export const trackInputSearch = (query, isSandBox = false, done = null) => (
  dispatch
) => {
  const {
    user: {
      credentials: { orgID },
    },
    mltracklibrary: {
      discreteEnabled,
      trackInputData: {
        query: { discreteFlags },
      },
    },
  } = store.getState();

  searchTransId = uuidv4();
  dispatch({
    type: actionTypes.TRACK_INPUT_QUERY,
    payload: query,
  });

  if (
    query ||
    (query && query.q) ||
    (query && query.origin !== OriginTypes.all) ||
    (query && query.filters && query.filters.length > 0) ||
    (query && query.all && query.all.q) ||
    (query && query.tagAll && query.All.q)
  ) {
    const options = {
      headers: {
        tid: searchTransId,
      },
    };
    dispatch({
      type: actionTypes.LOADING_TRACKINPUT,
    });

    axios
      .post(
        isSandBox ? "/librarysearchsandbox" : "/librarysearch",
        query,
        options
      )
      .then((res) => {
        if (res.data.tid === searchTransId) {
          if (res.data?.data.length > 0) {
            axios
              .post(
                `${orgID}/ctag/CheckExplicitCtag`,
                {
                  trackIdList: res.data.data.map((t) => t.id),
                },
                options
              )
              .then((post) => {
                if (post.data.tid === searchTransId) {
                  for (var item of res.data.data) {
                    let trackId = item.id;
                    item.cTagList = post.data.data.find(
                      (opt) => opt.trackId === trackId
                    )?.cTagList;
                  }
                  dispatch({
                    type: actionTypes.POST_UPDATE_TRACKINPUT,
                    payload: {
                      items: res.data.data,
                      originalItems: res.data.data,
                    },
                  });
                }
              })
              .catch((error) => {
                const {
                  mltracklibrary: {
                    trackInputData: { items, originalItems },
                  },
                } = store.getState();
                dispatch({
                  type: actionTypes.POST_UPDATE_TRACKINPUT,
                  payload: {
                    items: items,
                    originalItems: originalItems,
                  },
                });
              });
            let groupedData = groupBy(
              res.data.data,
              discreteFlags,
              discreteEnabled
            );
            dispatch({
              type: actionTypes.LOAD_TRACKINPUT,
              payload: {
                total: res.data.total,
                items: groupedData,
                originalItems: res.data.data,
                postUpdating: true,
                totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS
              },
            });
          } else {
            dispatch({
              type: actionTypes.LOAD_TRACKINPUT,
              payload: {
                total: 0,
                items: [],
                originalItems: [],
                postUpdating: false,
                totalRelation: pagingTotalRelation.EQUALS
              },
            });
          }
        }
        if (done) {
          done();
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ERROR_TRACKINPUT,
          payload: { e: err },
        });
      });
  } else {
    dispatch({
      type: actionTypes.CLEAR_TRACKINPUT,
      payload: [],
    });
  }
};

export const clearTrackInput = () => (dispatch) => {
  dispatch({
    type: actionTypes.CLEAR_TRACKINPUT,
    payload: [],
  });
};

export const prsSearch = (query, prsData, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
    mltracklibrary: {
      discreteEnabled,
      trackInputData: {
        query: { discreteFlags },
      },
    },
  } = store.getState();

  searchTransId = uuidv4();
  dispatch({
    type: actionTypes.TRACK_INPUT_QUERY,
    payload: query,
  });

  // if (
  //   query ||
  //   (query && query.q) ||
  //   (query && query.origin !== OriginTypes.all) ||
  //   (query && query.filters && query.filters.length > 0) ||
  //   (query && query.all && query.all.q) ||
  //   (query && query.tagAll && query.All.q)
  // ) {
  const options = {
    headers: {
      tid: searchTransId,
    },
  };
  dispatch({
    type: actionTypes.LOADING_PRS_SEARCH_TRACKINPUT,
  });

  axios
    .post(
      "/prsrecordingsearch",
      prsData,
      options
    )
    .then((res) => {
      if (res.data.tid === searchTransId) {
        if (res.data?.data.length > 0) {
          let groupedData = groupBy(
            res.data.data,
            discreteFlags,
            discreteEnabled
          );
          dispatch({
            type: actionTypes.LOAD_TRACKINPUT,
            payload: {
              total: res.data.total,
              items: groupedData,
              originalItems: res.data.data,
              postUpdating: true,
              totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS
            },
          });
        } else {
          dispatch({
            type: actionTypes.LOAD_TRACKINPUT,
            payload: {
              total: 0,
              items: [],
              originalItems: [],
              postUpdating: false,
              totalRelation: pagingTotalRelation.EQUALS
            },
          });
        }
      }
      if (done) {
        done(res.data, null);
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR_TRACKINPUT,
        payload: { e: err },
      });
      if (done) {
        done(null, err);
      }
    });
  // } else {
  //   dispatch({
  //     type: actionTypes.CLEAR_TRACKINPUT,
  //     payload: [],
  //   });
  // }
};

export const prsWorkSearch = (message, prsData, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  searchTransId = uuidv4();

  const options = {
    headers: {
      tid: searchTransId,
    },
  };
  dispatch({
    type: actionTypes.POSTING,
    payload: message
  });

  axios
    .post(
      "/prsworksearch",
      prsData,
      options
    )
    .then((res) => {
      if (done) {
        done(res.data, null);
        dispatch({
          type: actionTypes.POST_OK,
        });
      }
    })
    .catch((err) => {
      if (done) {
        done(null, err);
      }
    });

};

export const postItem = (action, message, items, id, type, done = null) => (
  dispatch
) => {
  if (id != null) {
    items = [];
    items.push(id);
  }

  let payload = {};
  payload.type = type;
  payload.ids = items;

  const {
    user: {
      credentials: { orgID },
    },
    mltracklibrary: { query },
  } = store.getState();

  const tId = uuidv4();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  //TODO : change the endpoint after backend implementation.
  axios
    .post(`${orgID}/contentadmin/Workspace/${action}`, payload, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.POST_OK,
        payload: items,
      });

      search(query)(dispatch);

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: err,
      });

      if (done) done(null, err);
    });
};

export const copyTrack = (message, item, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();
  item.orgId = orgID;
  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`${orgID}/contentadmin/copyTrack`, item, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.POST_OK,
        payload: res.data,
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: { e: err },
      });
    });
};
