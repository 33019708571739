import axios from "axios";
import store from "redux/Store";
import { pagingTotalRelation } from "redux/Types";
import { ValidateEmailTemplate } from "util/validations/MlAdminEmailTemplateActionsValidation";
import { v4 as uuidv4 } from "uuid";

module = "/email-template";


export const actionTypes = {
    LOADING: "LOADING" + module,
    LOAD: "LOAD" + module,
    ERROR: "ERROR" + module,
    QUERY: "QUERY" + module,
    SELECT_ITEMS: "SELECT_ITEMS" + module,
    FOCUS_ITEM: "FOCUS_ITEM" + module,
    EDIT_ITEM: "EDIT_ITEM" + module,
    REFRESH_ITEMS: "REFRESH_ITEMS" + module,
    POST_ERROR: "POST_ERROR" + module,
    POSTING: "POSTING" + module,
    POST_OK: "POST_OK" + module,
    CLEAR_ERRORS: "CLEAR_ERRORS" + module,
    LOAD_HISTORY: "LOAD_HISTORY" + module,
    REMOVE_HISTORY: "REMOVE_HISTORY" + module,
};


export const search = (query) => (dispatch) => {
    const {
      user: {
        credentials: { orgID },
      },
    } = store.getState();
    
    const tId = uuidv4();
    dispatch({
      type: actionTypes.LOADING,
    });
    
    axios
      .post(`${orgID}/mladmin/emailtemplate/search`, query, {
        headers: { tid: tId, orgid: orgID },
      })
      .then((res) => {
        if (res.data.tid === tId) {
          dispatch({
            type: actionTypes.LOAD,
            payload: res.data.results,
            total: res.data.total,
            totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ERROR,
          payload: { e: err },
        });
      });
  };


export const setQuery = (query , done = null) => (dispatch) => {
    dispatch({
        type: actionTypes.QUERY,
        payload: query,
    });

    if (done) done();
};

export const selectItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.SELECT_ITEMS,
    payload: items,
  });
};

export const focusItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.FOCUS_ITEM,
    payload: item,
  });
};

export const editItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.EDIT_ITEM,
    payload: item,
  });
};

export const postItem = (action, message, items, done = null, overrideVariableValidation = false) => (dispatch) => {
  if (items.emailTemplate && !overrideVariableValidation) {
    let errors = ValidateEmailTemplate(items.emailTemplate);
    if (errors) {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: errors
      });
      return;
    }
  }

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`${orgID}/mladmin/emailtemplate/${action}`, items, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.POST_OK,
      });
      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POSTING,
        payload: "Error while updating. please retry",
      });
      setTimeout(()=>{
        dispatch({
          type: actionTypes.POST_ERROR,
          payload: err.response?.data || { e: (err.message ? err.message : "Error while updating. please retry") },
        });
      }, 1000)
      if (done) done(null, err);
    });
};

export const refreshItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.REFRESH_ITEMS,
    payload: items,
  });
};

export const clearErrors = (done) => (dispatch) => {
  dispatch({
    type: actionTypes.CLEAR_ERRORS,
    payload: {},
  });
  if (done) done();
};

export const getEmailTemplateHistory = (emailId, done = null) => (dispatch) => {

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();
  axios
    .post(`${orgID}/mladmin/emailtemplate/searchHistory`, { emailId: emailId }, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.LOAD_HISTORY,
        payload: {
          emailId:emailId,
          history:res.data
        }
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      console.log(err)
    });
};

export const removeHistory = (emailIds) => (dispatch) => {
  dispatch({
    type: actionTypes.REMOVE_HISTORY,
    payload: emailIds,
  });
};