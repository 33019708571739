import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import mladminuserReducer from "./reducers/MlAdminUserReducer";
import MlAdminGroupReducer from "./reducers/MlAdminGroupReducer";
import MlTrackLibraryReducer from "./reducers/MlTrackLibraryReducer";
import MlAlbumReducer from "./reducers/MlAlbumReducer";
import MlSearchHistoryReducer from "./reducers/MlSearchHistoryReducer";
import SMAdminLibraryReducer from "./reducers/SMLibraryReducer";
import MLAdminLibraryReducer from "./reducers/MLLibraryReducer";

import userReducer from "./reducers/UserReducer";
import messagesReducer from "../modules/messages/redux/MessagesReducer";
import dataReducer from "./reducers/DataReducer";
import uiReducer from "./reducers/UiReducer";
import PlayerReducer from "./reducers/PlayerReducer";
import InboxReducer from "./reducers/InboxReducer";
import MlAdminRoleReducer from "./reducers/MlAdminRoleReducer";
import SmLogReducer from "./reducers/SmLogReducer";
import SmReportsReducer from "./reducers/SmReportsReducer";
import MlPlaylistReducer from "./reducers/MlPlaylistReducer";
import MlPlaylistInboxReducer from "./reducers/MlPlaylistInboxReducer";
import MlPlaylistOutboxReducer from "./reducers/MlPlaylistOutboxReducer";
import MlPlaylistContentReducer from "./reducers/MlPlaylistContentReducer";
import MlAlbumTrackReducer from "./reducers/MlAlbumTrackReducer";
import MlAdminSettingReducer from "./reducers/MlAdminSettingReducer";
import MlAdminAbuseReducer from "./reducers/MlAdminAbuseReducer";
import downloadReducer from "./reducers/DownloadReducer";

import MlLicAdminRuleReducer from "./reducers/MlLicAdminRuleReducer";
import MlLicAdminParamReducer from "./reducers/MlLicAdminParamReducer";
import MlLicAdminStatusReducer from "./reducers/MlLicAdminStatusReducer";
import MlLicFormReducer from "./reducers/MlLicFormReducer";
import MlLicFormContentReducer from "./reducers/MlLicFormContnetReducer";
import MlLicTrackReducer from "./reducers/MlLicTrackReducer";

import clearanceRuntime from "./reducers/ClearanceRuntimeReducer";
import MlUploadTracksReducer from "./reducers/MlUploadTracksReducer";
import MlUploadsessionReducer from "./reducers/MlUploadSessionReducer";
import MlAdminPlaylistCategoryReducer from "./reducers/MlAdminPlaylistCategoryReducer";
import MlPromoPlaylistReducer from "./reducers/MlPromoPlaylistReducer";
import SMContentTrackReducer from "./reducers/SMContentTrackReducer";
import CTagsReducer from "./reducers/CTagsReducer";
import CTagsRuleReducer from "./reducers/CTagsRuleReducer";
import MlPplLabelReducer from "./reducers/MlPplLabelReducer";
import MLPriorApprovalReducer from "./reducers/MLPriorApprovalReducer";
import MlPlayoutSessionReducer from "./reducers/MlPlayoutSessionReducer";
import MlPlayoutTracksReducer from "./reducers/MlPlayoutTracksReducer";
import MLTrackUpdateReducer from "./reducers/MLTrackUpdateReducer";
import MLAlbumUpdateReducer from "./reducers/MLAlbumUpdateReducer";
import MLAlbumUploadReducer from "./reducers/MLAlbumUploadReducer";
import MLSessionUploadTrackReducer from "./reducers/MLSessionUploadTrackReducer";
import AppLogReducer from "./reducers/MlAppLogReducer";

import TagCloudReducer from "../modules/tag-cloud/redux/TagCloudReducer";
import ResultWeightReducer from "../modules/result-weight/redux/ResultWeightReducer";
import SmSysAdminUserReducer from "../modules/sm-sys-admin/users/redux/SmSysAdminUserReducer";
import SmSysAdminOrganizationsReducer from "../modules/sm-sys-admin/organizations/redux/SmSysAdminOrganizationsReducer";
import SmSysAdminPaymentsReducer from "../modules/sm-sys-admin/payment/redux/SmSysAdminPaymentsReducer";
import HelpReducer from "../modules/ml-admin/help-admin/redux/HelpReducer";
import { actionTypes } from "./actions/UserActions";
import ContentRequestReducer from "modules/ml-content-tracker/content-request/redux/ContentRequestReducer";
import ContentAlertReducer from "modules/ml-content-tracker/content-alert/redux/ContentAlertReducer";
import PrsSearchButtonReducer from "util/prs-search/prs-search-button/redux/PrsSearchButtonReducer";
import SmUsageReportsReducer from "./reducers/SmUsageReportsReducer";
import EmailTemplateReducer from "modules/ml-admin/email-template/redux/EmailTemplateReducer";

const initialState = {};

const middleware = [thunk];

const reducers = combineReducers({
  user: userReducer,
  mladminuser: mladminuserReducer,
  mladmingroup: MlAdminGroupReducer,
  mladminrole: MlAdminRoleReducer,
  mladminsetting: MlAdminSettingReducer,
  mladminabuse: MlAdminAbuseReducer,
  mllicadminrule: MlLicAdminRuleReducer,
  mllicadminparam: MlLicAdminParamReducer,
  mllicadminstatus: MlLicAdminStatusReducer,
  mllicform: MlLicFormReducer,
  mllicformcontent: MlLicFormContentReducer,
  messages: messagesReducer,
  mllictrack: MlLicTrackReducer,
  mltracklibrary: MlTrackLibraryReducer,
  mlalbum: MlAlbumReducer,
  mlhistory: MlSearchHistoryReducer,
  mlplaylist: MlPlaylistReducer,
  mlplaylistinbox: MlPlaylistInboxReducer,
  mlplaylistoutbox: MlPlaylistOutboxReducer,
  mlplaylistcontent: MlPlaylistContentReducer,
  mlalbumtrack: MlAlbumTrackReducer,
  download: downloadReducer,
  clearanceRuntime: clearanceRuntime,
  data: dataReducer,
  UI: uiReducer,
  player: PlayerReducer,
  inbox: InboxReducer,
  smlibraryadmin: SMAdminLibraryReducer,
  smLog: SmLogReducer,
  smReports: SmReportsReducer,
  smUsageReports: SmUsageReportsReducer,
  mllibraryadmin: MLAdminLibraryReducer,
  mlUploadTracks: MlUploadTracksReducer,
  smContentTrackReducer: SMContentTrackReducer,
  mlUploadSession: MlUploadsessionReducer,
  mlPlaylistCategory: MlAdminPlaylistCategoryReducer,
  mlPromoPlaylist: MlPromoPlaylistReducer,
  mlCTags: CTagsReducer,
  mlCTagsRules: CTagsRuleReducer,
  tagcloud: TagCloudReducer,
  resultWeight: ResultWeightReducer, 
  mlppllabels: MlPplLabelReducer,
  mlPlayoutSessionReducer: MlPlayoutSessionReducer,
  mlPlayoutTracksReducer:MlPlayoutTracksReducer,
  mlTrackUpdateReducer: MLTrackUpdateReducer,
  mlAlbumUpdateReducer: MLAlbumUpdateReducer,
  mlPriorApprovalReducer:MLPriorApprovalReducer,
  mLAlbumUploadReducer:MLAlbumUploadReducer,
  mlSessionUploadTrackReducer:MLSessionUploadTrackReducer,
  help: HelpReducer,
  emailTemplate: EmailTemplateReducer,
  smsysadminuser: SmSysAdminUserReducer,
  smadminorganization: SmSysAdminOrganizationsReducer,
  smadminpayment: SmSysAdminPaymentsReducer,
  applogs: AppLogReducer,
  contentRequest: ContentRequestReducer,
  contentAlert: ContentAlertReducer,
  searchPrs: PrsSearchButtonReducer
});

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(...middleware));

const rootReducer = (state, action) => {
  if(action.type === actionTypes.SET_UNAUTHENTICATED){
    state.player.audioPlayer.pause();
    state.download.trackDownloads = new Map();
    return reducers(undefined, action);
  }
  return reducers(state, action);
}

const store = createStore(rootReducer, initialState, enhancer);

export default store;
