export const GroupRestrictionTypes = {
  all: "all",
  filtered: "filterd"
};

export const GroupRestrictionFilters = [
  {
    id:GroupRestrictionTypes.all, 
    label:"View All Content", 
  },
  {
    id:GroupRestrictionTypes.filtered, 
    label:"Content Group Only", 
  }
]