import { textLengths } from "../../redux/Types";

export const ValidatePayment = (Data) => {
    let errors = {}

    if (Data) {
        if (!Data.name) {
            errors.name = "Should not be empty";
        } else if (Data.name && Data.name.length > textLengths.TEXT) {
            errors.name = "Sorry, the name you entered is too long. Please try a shorter name";
        }

        if (Data.numberOfUsers && (Number.isInteger(Number(Data.numberOfUsers)) == false || Number(Data.numberOfUsers) < 0)) {
            errors.numberOfUsers = "Should be a positive integer"
        }
        if (Data.numberOfTrackPerPlaylist && (Number.isInteger(Number(Data.numberOfTrackPerPlaylist)) == false || Number(Data.numberOfTrackPerPlaylist) < 0)) {
            errors.numberOfTrackPerPlaylist = "Should be a positive integer"
        }
        if (Data.playlistSharingActionPerMonth && (Number.isInteger(Number(Data.playlistSharingActionPerMonth)) == false || Number(Data.playlistSharingActionPerMonth) < 0)) {
            errors.playlistSharingActionPerMonth = "Should be a positive integer"
        }
        if (Data.fee && ( Number(Data.fee) < 0)) {
            errors.fee = "Should be a positive value"
        }

        if (Data.numberOfUsers && Data.numberOfUsers > 100000 ) {
            errors.numberOfUsers = "Maximum value should be less than 100000";
        }
        if (Data.numberOfTrackPerPlaylist && Data.numberOfTrackPerPlaylist > 100000 ) {
            errors.numberOfTrackPerPlaylist = "Maximum value should be less than 100000";
        }
        if (Data.playlistSharingActionPerMonth && Data.playlistSharingActionPerMonth > 100000 ) {
            errors.playlistSharingActionPerMonth = "Maximum value should be less than 100000";
        }
        if (Data.fee && Data.fee > 100000 ) {
            errors.fee = "Maximum value should be less than 100000";
        }
    }


    if (Object.entries(errors).length === 0) {
        return null;
    } else {
        return errors;
    }
}