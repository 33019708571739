import { actionTypes } from "../actions/MlAdminGroupActions";
import { SortTypes } from "../../util/table-view/TableTypes";
import { defaultResultsPerPage, pagingTotalRelation } from "../Types";

const initialState = {
  items: [],
  totalItems: 0,
  totalRelation: pagingTotalRelation.EQUALS,
  selectedItem: {},
  selectedItems: [],
  loading: false,
  loadingMore: false,
  posting: { busy: false, message: "", errors: null },
  editingGroupObj: { busy: false, message: "", errors: null },
  editing: null,
  noMore: true,
  allGroups : [],
  query: {
    order: SortTypes.desc,
    orderBy: "updatedAt",
    page: 0,
    size: defaultResultsPerPage,
    q: "",
    filters: [],
  },
  errors: null,
};

export default function (state = initialState, action) {

  switch (action.type) {
    case actionTypes.LOADING:
      return {
        ...state,
        loading: true,
        loadingMore: false,
        errors: null,
        selectedItems: [],
        items: [],
      };

    case actionTypes.LOAD:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        errors: null,
        items: action.payload,
        totalItems: action.total,
        totalRelation: action.totalRelation,
        selectedItem: action.payload[0] || {},
        selectedItems: [],
        noMore: false,
      };
    
      case actionTypes.LOAD_ALL_GROUPS:
        return {
          ...state,
          allGroups : action.payload,
        };

    case actionTypes.ERROR:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        errors: action.payload,
        items: [],
        totalItems: 0,
        totalRelation: pagingTotalRelation.EQUALS,
        noMore: true,
      };

    case actionTypes.SELECT_ITEMS:
      return {
        ...state,
        selectedItems: action.payload,
      };
    case actionTypes.QUERY:
      return {
        ...state,
        query: action.payload,
      };
    case actionTypes.FOCUS_ITEM:
      return {
        ...state,
        selectedItem: action.payload,
      };

    case actionTypes.EDIT_ITEM:
      return {
        ...state,
        editing: action.payload,
      };

    case actionTypes.POSTING:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.POST_OK:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: null,
        },
      };

    case actionTypes.POST_ERROR:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: action.payload,
        },
      };

      case actionTypes.EDITING_GROUP_OBJECTS:
        return {
          ...state,
          editingGroupObj: {
            busy: true,
            message: action.payload,
            errors: null,
          },
        };
  
      case actionTypes.EDIT_GROUP_OBJECTS_OK:
        return {
          ...state,
          editingGroupObj: {
            busy: false,
            message: "",
            errors: null,
          },
        };
  
      case actionTypes.EDITING_GROUP_OBJ_ERROR:
        return {
          ...state,
          editingGroupObj: {
            busy: false,
            message: "",
            errors: action.payload,
          },
        };

      case actionTypes.REFRESH_ITEMS:
        return {
          ...state,
          items: action.payload,
        };

    default:
      return state;
  }
}
