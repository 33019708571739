export default {
  tableFocusedRow: {
    cursor: "pointer",
    background: "#b0cdea!important",
    color: "#000",
    "& .albumEnable .MuiAvatar-square":{
      display:"none",
    },
    "& .albumEnable .playTrack > div":{
      display:"block",
    },
    "& .albumEnable .playTrack":{
      display:"block",
      "& button":{
        display:"inline-flex",
        margin:"0 auto",
        opacity: "1"
      }
    },
    "& .albumEnable .playTrack>div":{
      opacity:"1"
    },
    "& td": {
      color: "#000 !important",
      background: "#b0cdea !important",
      "& strong": {
        color: "#000",
      },
      "& strong:hover": {
        color: "#000",
      },
      "& li:hover": {
        color: "#000",
        textDecoration: "underline",
      },
    },
    "&  div span": {
      color: "#000",
    },
    "& svg": {
      color: "#000 !important",
    },
    "& .clearanceIconSVG svg": {
      // color: "#dbbf63 !important",
    },
    "& .activeChart svg": {
      color: "gold !important",
    },
    "& span": {
      color: "#000 !important",
    },
    "& span.MuiBadge-colorSecondary": {
      color: "#fff !important",
    },
    "& span.explicitContents ":{
      color:"#fff !important",
    },
    "&  div.MuiChip-root": {
      color: "#504600",
      border: " 1px solid #a77f00",
      marginRight: "5px",
    },
    "&  a": {
      color: "#000000 !important",
      fontWeight: "500",
    },
    // Add error Class
 "&.testRow": {
  background:"#2e2c2c !important",
  cursor: "pointer",  
  "& .albumEnable .MuiAvatar-square":{
    display:"none",
  },
  "& .albumEnable .playTrack > div":{
    display:"block",
  },
  "& .albumEnable .playTrack":{
    display:"block",
    "& button":{
      display:"inline-flex",
      margin:"0 auto",
      opacity: "1"
    }
  },
  "& .albumEnable .playTrack>div":{
    opacity:"1"
  },
  "& td": {
    color: "#2e2c2c",
    fontWeight:"bold",
    background:"#494949",
    "& strong": {
      color: "#FFC0CB",
    },
    "& .errorIcon":{
      // background: "#8B0000 !important",
      color: "#f56262 !important",
      verticalAlign:"middle",
      fontSize:"20px",
    },
    "& strong:hover": {
      color: "#FFC0CB",
    },
    "& li:hover": {
      color: "#FFC0CB",
      textDecoration: "underline",
    },
    "&.MuiTableCell-alignCenter":{
      background:"#b0cdea",
    },
  },
  "&  div span": {
    color: "#FFC0CB",
  },
  "& svg": {
    color: "#000",
  },
  "& .clearanceIconSVG svg": {
    // color: "#dbbf63 !important",
  },
  "& .activeChart svg": {
    color: "gold !important",
  },
  "& span": {
    color: "#FFC0CB !important",
  },
  "& span.MuiBadge-colorSecondary": {
    color: "#fff !important",
  },
  "& span.explicitContents ":{
    color:"#fff !important",
  },
  "&  div.MuiChip-root": {
    color: "#504600",
    border: " 1px solid #a77f00",
    marginRight: "5px",
  },

  "&  a": {
    color: "#FFC0CB !important",
    fontWeight: "500",
  },
},
  },

  defaultRow:{
 // Add error Class
 "&.testRow": {
  background:"#2c2a2a",
  cursor: "pointer",  
  "& .albumEnable .MuiAvatar-square":{
    display:"none",
  },
  "& .albumEnable .playTrack > div":{
    display:"block",
  },
  "& .albumEnable .playTrack":{
    display:"block",
    "& button":{
      display:"inline-flex",
      margin:"0 auto",
      opacity: "1"
    }
  },
  "& .albumEnable .playTrack>div":{
    opacity:"1"
  },
  "& td": {
    color: "#f56262  !important",
    fontWeight:"bold",
    background:"#2c2a2a",
    "&.MuiTableCell-alignCenter":{
      background:"#2c2a2a",
    },
    "& strong": {
      color: "#FFC0CB",
    },
    "& .errorIcon":{
      color: "#f56262 !important",
      verticalAlign:"middle",
      fontSize:"20px"
    },
    "& strong:hover": {
      color: "#FFC0CB",
    },
    "& li:hover": {
      color: "#FFC0CB",
      textDecoration: "underline",
    },
  },
  "&  div span": {
    color: "#FFC0CB",
  },
  "& svg": {
    color: "rgba(255, 255, 255, 0.7) ",
  },
  "& .clearanceIconSVG svg": {
    // color: "#dbbf63 !important",
  },
  "& .activeChart svg": {
    color: "gold !important",
  },
  "& span": {
    color: "#FFC0CB !important",
  },
  "& span.MuiBadge-colorSecondary": {
    color: "#fff !important",
  },
  "& span.explicitContents ":{
    color:"#fff !important",
  },
  "&  div.MuiChip-root": {
    color: "#504600",
    border: " 1px solid #a77f00",
    marginRight: "5px",
  },
  "&  a": {
    color: "#FFC0CB !important",
    fontWeight: "500",
  },
},
  },
  
  tableContainer: {
    "& table td": {
      padding: "4px 16px !important",
    },
  },
  tableDataList: {
    tableLayout: "fixed",
    "& ..MuiCheckbox-root": {
      padding: "0px",
    },
    "& .MuiBadge-colorSecondary": {
      backgroundColor: "#f58e00",
    },
    "& tr.MuiTableRow-hover:hover": {
      // cursor: "pointer",
    },
    "& td": {
      padding: "3px 10px",
      fontSize: "0.7em",
      wordBreak: "break-word",
      cursor: "pointer",
      overflow: "hidden",
      // textOverflow: "ellipsis",
      // whiteSpace: "nowrap",
    },
    "& table td": {
      padding: "0px 10px !important",
      textAlign: "left",
      fontSize: "0.7em",
    },
    "& td:first-child": {
      background: "#000",
    },
    "& td:last-child": {
      // background: "#000",
    },
    "&  .Mui-checked svg": {
      color: "#0C853E",
    },
    "& .MuiTableRow-hover:hover": {
      backgroundColor: "rgb(76 76 76 / 62%)",
      "& td": {
        backgroundColor: "rgb(76 76 76 / 62%)",
      },
    },
    "& th": {
      padding: "3px 10px",
      textAlign: "left",
      textTransform: "uppercase",
      fontWeight: "normal",
      whiteSpace: "nowrap",
      verticalAlign: "middle",
      "& label": {
        fontSize: "0.75em",
      },
    },
    "& tr.tableFocusedRow .Mui-checked svg": {
      color: "#000 !important",
    },
    "& span": {
      // textTransform: "uppercase",
    },
    "& span > div": {
      width: "20px",
      height: "20px",
      borderRadius: "50%",
      margin: "5px 10px 5px 0px",
      display: "inline-block",
      verticalAlign: "middle",
    },
  },

  tableMainView: {
    overflow: "auto",
    width: "100%",
    marginTop: "65px",
    background: "#000",
    position: "absolute",
    height: "calc(100% - 0px)",
  },
  tableColumnWrap: {
    background: "none",
    boxShadow: "none",
    position: "relative",
    width: "100%",
    display: "block",   
    top:"-3px",
    "& div": {
      width: "100%",
      textTransform: "none",
    },
    "& .MuiOutlinedInput-root": {
      maxWidth: "inherit",
      minWidth: "inherit",
    },

     
  },
  tableUserDataWrap:{
    "&  .MuiInputLabel-formControl":{
      display:"none",
    },
    "& .MuiAutocomplete-inputRoot":{
      padding:"0",
      "& input":{
        paddingLeft:"30px !important",
        paddingRight:" 28px !important",
      }
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":{
      borderColor:" #46af22 !important",
    },
    "& .MuiOutlinedInput-notchedOutline legend":{
      maxWidth: "0px",
    }
    
  },
  tableActiveFilter: {
    "& .MuiOutlinedInput-root input": {
      background: "#424242",
      "& .MuiOutlinedInput-notchedOutline":{
        borderColor: "#46af22",
      }
    },
    "& .MuiInputAdornment-positionEnd":{
      marginRight: "22px !important",
    },
    "& input":{
      padding:"7.5px 12px 7.5px 38px !important",
      "&.MuiInputBase-input":{
        padding:"7.5px 27px 7.5px 38px !important", 
      }
   
    },
    "&.AdvactiveFilter .DateRangeButton":{
      "& .MuiButton-root":{
        marginRight:"25px",
      }
    }
  },
  tableContainer: {
    maxHeight: "100%",
    minHeight: "100%",
  },
  hasData: {
    "& .MuiSelect-iconOutlined": {
      right: "2px !important",
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      padding: "8px 32px 7px 30px !important",
    },
  },
  autocompleteWrapper: {
    width:"300px",  
    background:" #333",
    fontSize: "0.8em",
  },
  noDragHandle:{
    "& input":{
      borderRadius:"4px !important",
    },
    "& button":{
      right: "7px",
    }
  },
  tableFilterInput: {
    width: "100%",
    "& input": {
      borderRadius: "5px !important",
      padding: "7.5px 10px 7.5px 38px",
      fontSize: "0.75em",
    },
    "& div": {
      borderRadius: "5px !important",
      width: "100%",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#46af22 !important",
    },
  },
  tableFilterOptionInput: {
   "& .MuiSelect-iconOutlined": {
    right: "26px !important",
}
  },
  dateBetweenFilter: {
    "& .MuiInputLabel-root":{
      top: "-9px !important",
      left: "20px",
    },
    "& .MuiInputLabel-root.Mui-focused":{
      top: "2px !important",
      left: "9px",
    color:" #fff",
    }
  },
 
  tableFilterSelect: {
    borderRadius: "5px !important",
    padding: "0 0 0 36px!important ",
    minWidth: "150px",
    maxWidth: "150px",
    fontSize: "1.1em",
    "& input": {
      borderRadius: "5px !important",
      padding: "8.5px 14px 8.5px 31px",
       fontSize:"1em",
    },
    "& div": {
      padding: "6px 0px 5px 0px",
      borderRadius: "5px !important",
    },
    "&.MuiOutlinedInput-root:hover": {
      borderColor: "#46af22 !important",
      borderWidth: "1px!important",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#46af22 !important",
      borderWidth: "1px!important",
    },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#46af22 !important",
    },
  },

  tableCloseButton: {
    top: "41px",
    right: "17px",
    width: "25px !important",
    height: "inherit",
    display: "inline-block",
    padding: "1px 2px 0px 2px !important",
    zIndex: "9",
    position: "absolute",
    minWidth: "33px",
    lineHeight: "31px",
    marginBottom: "5px",
    paddingBottom: "0",
    color: "#808080",
  },
  tableFilterClear: {
    position: "absolute",
    right: "0",
    top: "0",
    minWidth: "28px",
    padding: "8px 5px",
  },

  tableFilterClearNodrag: {
    position: "absolute",
    right: "4px",
    top: "0",
    minWidth: "30px",
    padding: "10px 7px",
  },

  tableFilterCloud: {
    position: "absolute",
    right: "18px",
    top: "0",
    minWidth: "30px",
    padding: "10px 8px",
  },

  tableFilterCloudNoDrag: {
    position: "absolute",
    right: "6px",
    top: "0",
    minWidth: "30px",
    padding: "10px 7px",
  },

  tableFilterActive: {
    position: "absolute",
    right: "48px",
    top: "0",
    minWidth: "30px",
    padding: "9px",
  },

  tableFilterActiveNoDrag: {
    position: "absolute",
    right: "30px",
    top: "0",
    minWidth: "30px",
    padding: "10px",
    border : "red"
  },
  tableFilterIcon:{
    width: "25px !important",
    display: "inline-block",
    padding: "0 2px",
    minWidth: "33px",
    height: "29px  !important",
    paddingBottom: "0",
    marginBottom: "0",
    position: "absolute",
    left: "0",
    top: "0px",
    zIndex: " 9",
    lineHeight: "24px",
    "& span.notCondition": {
      top: "12px",
      left: "10px",
      width: "14px",
      height: "3px",
      zIndex: "9",
      position: "absolute",
      transform: "rotate(47deg)",
      background: "#ff4584",
      borderRadius: "3px",
      border: "1px solid #000000",
    },
    "& svg": {
      position: "relative",
      top: "3px",
    },
  },
  tableFilterIcons: {
    width: "25px !important",
    display: "inline-block",
    padding: "0 2px",
    minWidth: "30px",
    height: "inherit",
    paddingBottom: "0",
    marginBottom: "0",
    position: "absolute",
    left: "0",
    top: "0px",
    zIndex: " 9",
    lineHeight: "24px",
    "& span.notCondition": {
      top: "12px",
      left: "10px",
      width: "14px",
      height: "3px",
      zIndex: "9",
      position: "absolute",
      transform: "rotate(47deg)",
      background: "#ff4584",
      borderRadius: "3px",
      border: "1px solid #000000",
    },
    "& svg": {
      position: "relative",
      top: "3px",
    },
  },
  tableFilterList: {
     fontSize:"1em",
    "& li": {
       fontSize:"1em",
    },
    "& svg": {
      marginRight: "10px",
    },
  },
  tableInputLabel: {
    color: "#46af22",
    "&$tableInputFocused": {
      color: "#fff",
      borderWidth: "1px",
      borderColor: "#46af22 !important",
    },
  },
  tableInputOutlinedInput: {
    "&$tableInputFocused $tableNotchedOutline": {
      borderWidth: "1px",
      borderColor: "#46af22 !important",
    },
    "&:hover": {
      borderColor: "#46af22 !important",
      borderWidth: "1px!important",
    },
  },
  tableInputFocused: {
    borderWidth: "1px",
    borderColor: "#46af22 !important",
    "&:hover": {
      borderColor: "#46af22 !important",
      borderWidth: "1px!important",
    },
  },
  tableNotchedOutline: {
    borderWidth: "1px",
    // borderColor: "#616161 !important",
    "&:hover": {
      borderColor: "#46af22 !important",
      borderWidth: "1px!important",
    },
  },
  tableTextAvailable: {
    background: "#424242!important",
    "& .MuiSelect-iconOutlined":{
      right: "23px !important",
  }
  },
  tableVisuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tableLabel: {
    cursor: "pointer"
  },
  tableEmpty: {
    position: "absolute",
    top: "50%",
    bottom: "0",
    left: "0",
    right: "0",
    width: "100%",
    fontSize: "12px",
    textAlign: "center",
    bottom: "18px",
    "& .MuiCircularProgress-colorPrimary": {
      color: "green",
    },
  },
  tableArtwork: {
    width: "22px",
    height: "22px",
    borderRadius: "3px",
     fontSize:"1em",
    background: "#d8d8d5",
    borderRadius: "3px",
  },
  tableMultiRow: {
    position: "relative",
    zIndex: "0",
    tableLayout: "fixed",
    fontSize:"1.4em !important",
    "& td": {
      border: "none",
    },
    "& td:first-child": {},
    "& td:nth-child(2)": {},
  },
  tableMultiCell: {
    padding: "0 !important",
    "& .MuiAvatar-square": {
      paddingTop: "5px",
    },
    "& .MuiAvatar-square": {
      paddingTop: "5px",
    },
  },
  tableCrushedData: {
    paddingLeft: "108px",
    color: "#999",
    paddingBottom: "0",
  },
  tableCrushedlabel: {
    color: "#999",
    fontSize: "11px",
  },
  tableCrushedDetail: {
    color: "#fff",
    marginLeft: "4px",
    marginRight: "8px",
  },
  tableCrushedTags: {
    display: "block",
    "& .MuiChip-root": {
      background: "none",
      border: "1px solid #FFC400",
      color: "#FFC400",
      padding: "4px 2px",
      fontSize: "1em !important",
      height: "inherit",
      marginRight: "5px",
      marginBottom: "6px",
      marginTop: "2px",
      borderRadius:"16px",
      width:"inherit"
    },
  },
  
  tableContainerRemoveMinheight:{
    minHeight:"inherit",
    height: "calc(100% - 47px)",
    overflow:"auto",
    position:"relative",
  },
  pagingGotoPageWrap:{
    display: "flex",
    alignItems: "center",
    marginLeft: "10px",
    borderLeft: "1px solid #5f5f5f",
    paddingLeft: "15px",
    fontSize:"11px",
    "& div.MuiAutocomplete-inputRoot":{
      fontSize:"11px",
      background: "#333",
      width: "75%",
    },
    "& .MuiAutocomplete-hasPopupIcon":{
      fontSize:"11px",
      marginLeft:"5px",
    }
  },
  globalPagination:{
    "& .MuiSelect-select":{
      background:"#5d5d5d",
      borderRadius: "3px",
    },
    "& .Mui-focused .MuiSelect-select":{
      background:"#807f7f",
    }
   }
};
