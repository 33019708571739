import axios from "axios";
import store from "redux/Store";
import { pagingTotalRelation } from "redux/Types";
import { v4 as uuidv4 } from "uuid";
import { ValidateGroup } from "../../util/validations/MlAdminGroupsActionsValidation";
module = "/mlAdmin/adminGroups/";
export const actionTypes = {
  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,
  ERROR: "ERROR" + module,
  FOCUS_ITEM: "FOCUS_ITEM" + module,
  SELECT_ITEMS: "SELECT_ITEMS" + module,
  QUERY: "QUERY" + module,
  EDIT_ITEM: "EDIT_ITEM" + module,
  POSTING: "POSTING" + module,
  POST_OK: "POSTOK" + module,
  POST_ERROR: "POST_ERROR" + module,
  EDITING_GROUP_OBJECTS: "EDITING_GROUP_OBJECTS" + module,
  EDIT_GROUP_OBJECTS_OK: "EDIT_GROUP_OBJECTS_OK" + module,
  EDITING_GROUP_OBJ_ERROR: "EDITING_GROUP_OBJ_ERROR" + module,
  LOAD_ALL_GROUPS: "LOAD_ALL_GROUPS" + module,
  REFRESH_ITEMS : "REFRESH_ITEMS" + module,
};

var tId = null;

export const editItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.EDIT_ITEM,
    payload: item,
  });
};

export const postItem = (action, message, items, done = null) => (dispatch) => {
  if (items.group) {
    let errors = ValidateGroup(items.group);
    if (errors) {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: errors
      });
      return;
    }
  }

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`${orgID}/mladmin/groups/${action}`, items, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      //if (res.data.tid === tId) {
      dispatch({
        type: actionTypes.POST_OK,
      });
      if (done) done(res.data, null);
      //}
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: err.response?.data || { e: (err.message ? err.message : "Error in creating or updating. please retry") },
      });
      if (done) done(null, err);
    });
};

export const setQuery = (query, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.QUERY,
    payload: query,
  });

  if (done) done();
};

export const selectItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.SELECT_ITEMS,
    payload: items,
  });
};

export const focusItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.FOCUS_ITEM,
    payload: item,
  });
};
export const search = (query) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  dispatch({
    type: actionTypes.LOADING,
  });

  tId = uuidv4();

  axios
    .post(`${orgID}/mladmin/groups/search`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        dispatch({
          type: actionTypes.LOAD,
          payload: res.data.results,
          total: res.data.total,
          totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
    });
};

export const getAllMLAdminGroups = () => (dispatch) => {

  const query = {
    orderBy: "updatedAt",
    page: 0,
    q: "",
    size: 10000
  };
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  tId = uuidv4();

  axios
    .post(`${orgID}/mladmin/groups/search`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        dispatch({
          type: actionTypes.LOAD_ALL_GROUPS,
          payload: res.data.results,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
    });
};

export const addToGroups = (payload, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  dispatch({
    type: actionTypes.EDITING_GROUP_OBJECTS,
    payload: "Adding to groups...",
  });

  axios
    .post(`${orgID}/mladmin/groups/addobjects`, payload, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.EDIT_GROUP_OBJECTS_OK,
      });
      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.EDITING_GROUP_OBJ_ERROR,
        payload: err.response?.data || { e: (err.message ? err.message : "Error in adding to groups. Please retry") },
      });
      if (done) done(null, err);
    });
};


export const removeFromGroups = (payload, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  dispatch({
    type: actionTypes.EDITING_GROUP_OBJECTS,
    payload: "Removing from groups...",
  });

  axios
    .post(`${orgID}/mladmin/groups/removeobjects`, payload, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.EDIT_GROUP_OBJECTS_OK,
      });
      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.EDITING_GROUP_OBJ_ERROR,
        payload: err.response?.data || { e: (err.message ? err.message : "Error in removing from groups. Please retry") },
      });
      if (done) done(null, err);
    });
};

export const refreshItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.REFRESH_ITEMS,
    payload: items,
  });
};

export const checkContentUser = (items, done = null) => (dispatch) => {  

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  axios
    .post(`${orgID}/mladmin/groups/checkContentUser`, items, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (done) done(res.data, null);
    })
    .catch((err) => {
      if (done) done(null, err);
    });
};
