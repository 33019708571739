import React, { Component } from "react";
import {
  withStyles,
  MenuItem,
  Button,
  Menu,
  CircularProgress,
} from "@material-ui/core";

import { connect } from "react-redux";
import { loadRuntime } from "../../../redux/actions/ClearanceRuntimeActions";
import trackFields from "../../../_module/definition/trackFields";
import { AiOutlineClear } from "react-icons/ai";
import { _PermittedContent } from "../../../_module/utils/PermittedContent";
import { styles } from "./Styles.css";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import { withRouter } from "react-router";
import { FiMusic } from "react-icons/fi";
import { v4 as uuidv4 } from "uuid";
import Confirm from "util/Confirm";
import {
  postItem,
} from "../../../redux/actions/MlPlaylistActions";
import { _mlPlaylistApplyStatuses, _mlPlaylists } from "_module/definition/MlPlaylists";

export const PlaylistStatus = {
  InProgress: 0,
  Completed: 1,
  Closed: 2,
};

export const PlaylistStatusLabel = [
  {id: PlaylistStatus.InProgress , label : "In Progress" , color : "#13A44F"},
  {id: PlaylistStatus.Completed , label : "Completed" , color : "#04ACF4"},
  {id: PlaylistStatus.Closed , label : "Closed" , color : "#E38800"},

]



export default class PlaylistStatusMenu extends Component {
  // canRunCFWizard = false;
  // canOverrideClearance = false;

  constructor(props) {
    super();

    this.state = {
      anchorEl: null,
      loading: false,
      errors: null,
      messageOpen: false,
      status: null
    };

    // this.canRunCFWizard = _PermittedContent([
    //   _mlLicensing,
    //   _mlLicensingCanRunWizard,
    // ])
    //   ? true
    //   : false;

    // this.canOverrideClearance = _PermittedContent([
    //   _mlLicensing,
    //   _mlLicensingOverideRestrictions,
    // ])
    //   ? true
    //   : false;

  }

 

  handleMenuClick = (event) => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = (event) => {
    if (event) event.stopPropagation();
    this.setState({ anchorEl: null });
  };

  handeConfirmMessageOpen = (status) => {
    this.setState({ messageOpen: true, status: status.id, anchorEl: null });
  };

  handleSave = (e) => {
    const {
      postItem,
      playlistData,
      user,
      onCloseMenu
    } = this.props;

    const { messageOpen } = this.state;

    if (messageOpen) {
      this.setState({ messageOpen: false });
    }

    let newplaylist = playlistData;

    if(_PermittedContent([_mlPlaylists,_mlPlaylistApplyStatuses]) && playlistData.createdBy == user.id){
      newplaylist = {...newplaylist , playlistStatus: this.state.status}
    }
    this.setState({loading: true});

    postItem("save", null, { entity: newplaylist }, (data, error) => {
      this.setState({loading: false});
      if (error) {
        // alert("Error in creating or updating. please retry");
      } else if (data) {
        if(onCloseMenu){
          onCloseMenu();
        }
        playlistData = {...playlistData, playlistStatus: this.state.status};
        
      }
    });
  };

  onCancel = (e) =>{
    this.setState({ messageOpen: false,});
  }

  componentDidMount() {
    const {
    } = this.props;

    // if (!loading && !runtime && !errors) {
    //   loadRuntime(null);
    // }
  }



  

  render() {
    const { anchorEl, loading, errors } = this.state;
    const {
      classes,
      playlistData
    } = this.props;


    return (
      <>
        
        <Confirm
              name="unsavedDialog"
              classes
              isOpen={this.state.messageOpen}
              title="Confirmation Alert"
              content="Are you sure, do you want to change the status?"
              confirm="Yes"
              cancel="No"
              onConfirm={(e) =>{ this.handleSave(e)}}
              onCancel={this.onCancel}
              // extentions={[{
              //   text:"Cancel", 
              //   action:()=>{
              //     this.setState({ messageOpen: false });
              //   }
              // }]}
            />
           <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={this.handleMenuClick}
          className={classes.PlaylitStatusDropMenu}
          disabled={playlistData.playlistStatus == PlaylistStatus.Closed}
        >
         
            <span className={classes.clearMe} title={playlistData.playlistStatus == PlaylistStatus.InProgress || playlistData.playlistStatus? PlaylistStatusLabel.find(e => e.id == playlistData.playlistStatus).label : "Open" }>
            {playlistData.playlistStatus == PlaylistStatus.InProgress || playlistData.playlistStatus? 
            <>
            <span  style={{
              backgroundColor: PlaylistStatusLabel.find(e => e.id == playlistData.playlistStatus).color || "",
              width: "22px",
              height: "22px",
              borderRadius: "50%",
              marginRight:"8px",
              display: "inline-block",
              verticalAlign: "middle",
          }} class="trafficLight"></span> 
          <span>{ PlaylistStatusLabel.find(e => e.id == playlistData.playlistStatus).label }</span>
          </>
             : ""}{loading && (
              <CircularProgress
                size={20}
                className={classes.progress}
              />
            )} <ArrowDropDownIcon />
            </span>
        </Button>

        {anchorEl  && (
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{
              horizontal: "left",
              vertical: "bottom",
            }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={this.handleMenuClose}

          >
            {PlaylistStatusLabel.map(d=>{
              return <MenuItem 
              disabled={playlistData.playlistStatus == PlaylistStatus.Closed || d.id == PlaylistStatus.Closed} 
              onClick={()=>this.handeConfirmMessageOpen(d)} 
              // value={PlaylistStatus.Closed}
              >
              <span  style={{
                  backgroundColor: PlaylistStatusLabel.find(e => e.id == d.id).color || "",
                  width: "22px",
                  height: "22px",
                  borderRadius: "50%",
                  marginRight:"8px",
                  display: "inline-block",
                  verticalAlign: "middle",
              }}></span> 
              <span>{ d.label }</span>
            </MenuItem>
            }) 
             }

          </Menu>
        )}
      </>
    );
  }
}
